/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SkeletonNew } from './SkeletonNew';
import GameThumbnailList, { SectionWrapper } from '../GameThumbnailList';
import { providerFilter, searchFilter } from './FilteredGames';
import useQuery from '../../../hooks/useQuery';
import useActiveGames from '../../../hooks/games/useActiveGames';
import { isMobile } from 'react-device-detect';

const moreDisplay = isMobile ? 12 : 24

export const AllGames = () => {
  const { query } = useQuery();
  const searchText = query.get('search');
  const { activeProvider } = useActiveGames();
  const [displayGamesCount, setDisplayGamesCount] = React.useState(12);
  const gamesByIds = useSelector((state) => state.games?.gamesByIds);

  const activeProviderId = activeProvider?.id;

  const filteredGames = React.useMemo(() => {
    if (Object.values(gamesByIds)?.length) {
      const allGames = Object.values(gamesByIds)
      ?.filter((game) => searchFilter(game, searchText))
      ?.filter((game) => providerFilter(game, activeProviderId, 'provider'));

      const dividedGames = {
        newGames: allGames.filter(game => game.is_new),
        normalGames:  allGames.filter(game => !game.is_new)
      }

      return dividedGames
    }
    return {newGames: [], normalGames: []}
  }, [activeProviderId, searchText, gamesByIds]);

  if (filteredGames?.newGames?.length < 12) {
    return (
      <SectionWrapper>
        <h2 className="is-size-4 has-text-weight-bold has-text-primary container has-text-centered">
          All games
        </h2>
        <div
          id="game-grid"
          className="m-game-grid columns is-multiline is-mobile parent-grid skeleton"
        >
          {new Array(6).fill(true).map((_, i) => (
            <SkeletonNew key={i} />
          ))}
        </div>
      </SectionWrapper>
    );
  }

  const filteredCategory = {
    id: 999995,
    slug: 'all-games',
    name: 'All games',
    displayGamesCount: displayGamesCount,
    display_name: 'All games',
    moreGamesToDisplay: 0,
    position: 0,
    showWinnersList: false,
    isMobile: false,
    loadAllGames: true,
    games: [...filteredGames?.newGames, ...filteredGames?.normalGames]?.map((game) => ({
      ...game,
      mega_thumb: false,
      vertical_thumbnail: {},
    })),
    count: filteredGames?.length,
    totalCount: filteredGames?.length,
  };

  return (
    <GameThumbnailList
      category={filteredCategory}
      maxLimit={filteredCategory?.displayGamesCount}
      loadMoreButton={
        <LoadMoreButton
          handleClick={() => setDisplayGamesCount((count) => count + moreDisplay)}
          allGamesLoaded={filteredGames?.length <= displayGamesCount}
        />
      }
    />
  );
};

const LoadMoreButton = ({ handleClick, allGamesLoaded }) => {
  const { t } = useTranslation();

  if (allGamesLoaded) return null;

  return (
    <div className="level m-button-holder">
      <div className="level-item">
        <button
          type="button"
          onClick={handleClick}
          className={`button m-game-grid-button is-size-4 is-primary is-outlined is-centered is-uppercase`}
        >
          {t('common:more_games')}
        </button>
      </div>
    </div>
  );
};
