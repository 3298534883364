import React from 'react';
import PropTypes from 'prop-types';
import Casino from '@dialinvest/react-casino';

const TournamentsPageTextContent = ({
  page,
}) => {
  const [pageContent, setPageContent] = React.useState([]);

  async function fetchPage(id) {
    const result = await new Casino.FetchContent(`pages/${id}`).perform();

    setPageContent(result);

  }

  React.useEffect(() => {
    if (page[0] !== undefined) {
      fetchPage(page[0].id);
    }
  }, [page]);

  return (
    <>
      <section className="section m-tournament-main">
        <div className="columns is-tablet">
          <div className="column is-12-mobile is-9-tablet">
            <div
              className="m-tournaments-main-subtitle has-background-color-linear tournaments-subtitle is-size-4 is-size-6-mobile has-text-weight-bold"
            >
              {pageContent !== undefined
                ? page.title
                : ''}
            </div>

            <div className="content my-6 ml-4 is-size-6 color-primary">
              {page !== undefined
                ? <p dangerouslySetInnerHTML={{ __html: page.description }} />
                : ''}
            </div>
          </div>

          <div className="column m-tournament-sidebar is-12-mobile is-3-tablet">
            <aside className="columns">
              <img src="/images/os_t_sidebar.jpg" alt="Tournament Sidebar" />
            </aside>
          </div>

        </div>
      </section>
    </>
  );
};

TournamentsPageTextContent.propTypes = {
  page: PropTypes.instanceOf(Array),
};

TournamentsPageTextContent.defaultProps = {
  page: [],
};

export default TournamentsPageTextContent;
