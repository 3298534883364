import React, { useRef } from 'react';
import PropTypes from 'prop-types';

const BaseCheckbox = (props) => {
  const mainClassName = ['columns', 'is-mobile', 'field', 'm-field-checkbox'];
  let errorMessage = '';
  const checkBoxInput = useRef();

  const {
    errorDescription,
    value, onChange, validation, title, description, valid, touched
  } = props;

  if (!valid && touched && validation.required) {
    errorMessage = <p className="help is-danger">{errorDescription || '*This field is require'}</p>;
  }
  return (
    <div>
      <div className={mainClassName.join(' ')}>
        <input
          style={{ display: 'none' }}
          className="is-checkradio is-success"
          value={value}
          checked={value}
          onChange={onChange}
          id={`registration_${title}`}
          type="checkbox"
          name={`registration_${title}`}
          ref={checkBoxInput}
        />
        <label className="column" htmlFor={`registration_${title}`} dangerouslySetInnerHTML={{ __html: description }} />
      </div>
      {errorMessage}
    </div>
  );
};

BaseCheckbox.propTypes = {
  errorDescription: PropTypes.string.isRequired,
  validation: PropTypes.shape({
    required: PropTypes.bool.isRequired,
  }).isRequired,
};


export default BaseCheckbox;
