import React from 'react';
import PropTypes from 'prop-types';

const BaseSelect = (props) => {
  const {
    valid,
    touched,
    value,
    validation,
    items,
    title,
    onChange,
    config,
    description,
    errorDescription,
    columnClass,
  } = props;

  const isDanger = 'is-danger';
  const isSuccess = 'is-success';
  const selectClasses = ['select', 'is-medium'];
  let newItems = [];
  let itemCount = 0;
  let selected = false;
  let warningMessage = '';
  const errorDescriptionLabel = errorDescription || 'This field is invalid';
  const columnClasses = (typeof columnClass !== 'undefined') ? columnClass : '';
  if (!valid && touched && validation.required) {
    selectClasses.push(isDanger);
    warningMessage = <p className="help is-danger">{errorDescriptionLabel}</p>;
  } else if (valid && touched && validation.required) {
    selectClasses.push(isSuccess);
  }

  if (items) {
    newItems = items.map((item) => {
      itemCount += 1;
      selected = item.value === value;
      return <option key={itemCount} value={item.value} selected={selected}>{item.name}</option>;
    });
  }
  itemCount += 1;

  return (
    <div className={`column is-3 ${columnClasses} field`}>
      <label htmlFor={title} className="label">{description}</label>
      <div className="control has-icons-left">
        <div className={selectClasses.join(' ')} required={validation.required}>
          <select onChange={onChange}>
            {newItems}
          </select>
        </div>
        <span className="icon is-small is-left">
          <i className={config.leftIcon} />
        </span>
        {warningMessage}
      </div>
    </div>
  );
};

BaseSelect.propTypes = {
  errorDescription: PropTypes.string.isRequired,
  valid: PropTypes.bool.isRequired,
  required: PropTypes.bool,
  validation: PropTypes.instanceOf(Object).isRequired,
  config: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string,
  touched: PropTypes.bool,
};

BaseSelect.defaultProps = {
  required: undefined,
  title: '',
  touched: false,
};

export default BaseSelect;
