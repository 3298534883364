/* eslint-disable import/prefer-default-export */

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import usePlayer from '../usePlayer';
import { gamesApi } from '../../lib/api/cms/gamesService/Games';
import { setActiveGamesCategory } from '../../redux/actions/gamesActions/gamesAction';
import { usePersonalizedSite } from './useGetPersonalizedSite';

export const GamesCache = new Map();

export const GamesKeys = {
  topGames: 'top-games',
  recommendedGAmes: 'recommended-games',
  popularGames: 'popular-games',
};

export function useGetTopGames() {
  const { player } = usePlayer();
  const [games, setGames] = React.useState();
  const [state, setState] = React.useState('loading');
  const dispatch = useDispatch();
  const { data: personalizedGames, state: personalizedState } =
    usePersonalizedSite();

  const partyId = player?.partyId;

  useEffect(() => {
    if (games?.length || state === 'error' || partyId) return;

    const getData = async () => {
      setState('loading');
      let topGames = GamesCache.get(GamesKeys.topGames);

      try {
        dispatch(setActiveGamesCategory({ topGames: true }));

        if (!topGames) {
          const response = await gamesApi.getRecommendedGames({
            recommendationType: 'new_player_recommendations',
          });

          if (!response?.data?.items?.length) {
            setState('error');
            return;
          }
          topGames = (() => {
            if (response?.data?.items?.length >= 12) {
              return response?.data?.items?.slice(0, 12);
            }
            if (response?.data?.items?.length >= 6) {
              return response?.data?.items?.slice(0, 6);
            }
            if (response?.data?.items?.length < 6) {
              return [];
            }
          })();

          if (!topGames?.length) {
            setState('error');
            return;
          }

          GamesCache.set(GamesKeys.topGames, topGames);
        }
        setGames(topGames);
        setState('idle');
      } catch (error) {
        setState('error');
      }
    };
    getData();
  }, [partyId, games, state, dispatch]);

  useEffect(() => {
    if (state === 'error') {
      dispatch(setActiveGamesCategory({ topGames: false }));
    }
  }, [state, dispatch]);

  useEffect(() => {
    if (partyId) {
      setState(personalizedState);
      dispatch(setActiveGamesCategory({ topGames: true }));
      if (personalizedGames?.players_like_you?.length) {
        const modifedGames = (() => {
          if (personalizedGames?.players_like_you?.length >= 12) {
            return personalizedGames?.players_like_you?.slice(0, 12);
          }
          if (personalizedGames?.players_like_you?.length >= 6) {
            return personalizedGames?.players_like_you?.slice(0, 6);
          }
          if (personalizedGames?.players_like_you?.length < 6) {
            return [];
          }
        })();
        setGames(modifedGames);
      }
    }
  }, [partyId, setState, personalizedState, personalizedGames, dispatch]);

  return {
    games,
    state,
  };
}
