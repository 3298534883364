export const FAVORITE_GAMES_CATEGORY_ID = 999999;

export const favoriteGamesCategory = {
  id: FAVORITE_GAMES_CATEGORY_ID,
  name: 'Favorite Games',
  name_english: 'Favorite Games',
  slug: 'favorite-games',
  position: 9999,
  position_mobile: 9999,
  games_to_display_by_category: Infinity,
  games_to_display_by_category_mobile: Infinity,
  games_to_display_on_home: Infinity,
  games_to_display_on_home_mobile: Infinity,
  total_games_in_category: null,
  total_games_in_category_mobile: null,
  more_games_to_display: Infinity,
  more_games_to_display_mobile: Infinity,
  load_all_games: true,
  load_all_games_mobile: true,
  category_page: null,
  icon: null,
  icon_mobile: null,
  display_winners_widget: false,
  display_filter: true,
};

export const favoriteGamesMap = {
  'en-int': {
    name: 'Favorite Games',
    slug: 'favorite-games',
  },
  'en-ca': {
    name: 'Favorite Games',
    slug: 'favorite-games',
  },
  'en-za': {
    name: 'Favorite Games',
    slug: 'favorite-games',
  },
  'es-pe': {
    name: 'Juegos Favoritos',
    slug: 'juegos-favoritos',
  },
};

export const getDisplayGamesByCategory = (category) => {
  const isMobileDevice = /Mobi/i.test(window.navigator.userAgent);
  const ifHomePage = !window.location.pathname.split('/')[2]?.length;

  const displayedGames = {
    maxLimit: null,
    limit: null,
    moreGamesToDisplay: null,
    isMobile: isMobileDevice,
    loadAllGames: null,
  };

  if (category) {
    const displayedGamesOnHomePage = isMobileDevice
      ? category.games_to_display_on_home_mobile
      : category.games_to_display_on_home;

    const displayedGamesOnOtherPage = isMobileDevice
      ? category.games_to_display_by_category_mobile
      : category.games_to_display_by_category;

    const totalGames = isMobileDevice
      ? category.total_games_in_category_mobile
      : category.total_games_in_category;

    const moreGamesToDisplay = isMobileDevice
      ? category.more_games_to_display_mobile
      : category.more_games_to_display;

    const loadAllGames = isMobileDevice
      ? category.load_all_games_mobile
      : category.load_all_games;

    return {
      ...displayedGames,
      maxLimit: totalGames,
      limit: ifHomePage ? displayedGamesOnHomePage : displayedGamesOnOtherPage,
      moreGamesToDisplay,
      isMobile: isMobileDevice,
      loadAllGames,
    };
  }

  return displayedGames;
};

export const countFeaturedImages = games => games
  ?.filter(game => game?.mega_thumb || game.vertical_thumbnail?.id)
  .map(game => ({
    megaThumb: game?.mega_thumb || false,
    verticalThumb: game?.vertical_thumbnail?.id || false,
  }))
  .reduce(
    (final, game) => {
      const result = {
        ...final,
      };
      if (game?.verticalThumb) result.verticalThumb = final.verticalThumb + 1;
      if (game?.megaThumb) result.megaThumb = final.megaThumb + 1;
      result.all = result.megaThumb * 4 + result.verticalThumb * 2;

      return result;
    },
    { verticalThumb: 0, megaThumb: 0, all: 0 },
  );

export const getColumnCount = () => {
  const wide = window.innerWidth > 1131;
  const tablet = window.innerWidth < 1131 && window.innerWidth > 767;
  const mobile = window.innerWidth < 767;

  let columnCount = 0;

  if (wide) columnCount = 6;
  if (tablet) columnCount = 4;
  if (mobile) columnCount = 2;

  return { columnCount };
};

export const calculateMoreGames = (games, showWinnersList, limit, maxLimit) => {
  if (maxLimit || !games?.length) return 0;

  const displayedGames = games?.slice(0, limit);
  const { columnCount } = getColumnCount();
  const {
    megaThumb,
    verticalThumb,
    all: allFeaturedImages,
  } = countFeaturedImages(displayedGames);

  const winnersList = showWinnersList ? 1 : 0;

  const currentLength = limit + winnersList;

  const bigThumb = megaThumb * 4;
  const longThumb = verticalThumb * 2;
  const smallThumbs = currentLength - megaThumb - verticalThumb;

  const rowCount = Math.ceil((allFeaturedImages + smallThumbs) / columnCount);
  const lastRow = displayedGames.slice(-columnCount);
  const additionalRow = lastRow
    ?.find(game => game.mega_thumb || game.vertical_thumbnail?.id)?.id ? 1 : 0;
  const gridSize = columnCount * (rowCount + additionalRow);

  const moreGamesCount = gridSize - bigThumb - longThumb - smallThumbs;
  let additionalCount = 0;

  if (moreGamesCount) {
    additionalCount = calculateMoreGames(games, showWinnersList, currentLength + moreGamesCount);
  }

  return moreGamesCount + additionalCount;
};

export const buildGameCategoryObject = (categoryItem, games, nextUrl = null, totalCount = null) => {
  const {
    limit, maxLimit, isMobile, loadAllGames, moreGamesToDisplay,
  } = getDisplayGamesByCategory(categoryItem);

  const moreGamesCount = calculateMoreGames(
    games,
    categoryItem?.display_winners_widget,
    limit,
    maxLimit,
  );

  const initialCount = (maxLimit || limit) + moreGamesCount
      || +process.env.REACT_APP_GAME_LIMIT_HOMEPAGE;

  const categoryObj = {
    id: categoryItem.id,
    isProvider: !!categoryItem?.platform_code,
    slug: categoryItem.slug,
    name: categoryItem.name,
    display_name: categoryItem?.display_name,
    games,
    showWinnersList: categoryItem?.display_winners_widget || false,
    count: games?.length,
    totalCount,
    position: isMobile ? categoryItem.position : categoryItem.position_mobile,
    isMobile,
    loadAllGames: loadAllGames !== undefined ? loadAllGames : true,
    moreGamesToDisplay:
      moreGamesToDisplay || +process.env.REACT_APP_GAME_LIMIT_HOMEPAGE,
    displayGamesCount: initialCount,
    initialCount,
    limit,
    maxLimit,
    nextUrl,
    type: categoryItem.type,
    isWhiteListed: categoryItem?.isWhiteListed,
  };

  return { ...categoryObj };
};

export const removeDomainWhitelistedGamesFromCategories = (
  gamesByCategories,
  domainWhiteListedProviders,
) => Object.entries(gamesByCategories).reduce(
  (categoriesObj, [categoryId, value]) => {
    const filteredGames = value.games?.filter(
      game => !domainWhiteListedProviders.includes(game.provider.id),
    );

    // Calculae again the displayed games
    const moreGames = calculateMoreGames(
      filteredGames,
      value.showWinnersList,
      value.limit,
      value.maxLimit,
    );

    return {
      ...categoriesObj,
      [categoryId]: {
        ...value,
        games: filteredGames,
        count: filteredGames.length,
        displayGamesCount:
          (value.maxLimit || value.limit) + moreGames
          || +process.env.REACT_APP_GAME_LIMIT_HOMEPAGE,
      },
    };
  },
  {},
);

export const removeDomainWhitelistedFromFavoriteGames = (games, providers) => games
  ?.filter(game => !providers
    .some(provider => provider.platform_code === game.platformCode));

export const removeDomainWhitelistedGames = (games, notAllowedProviders) => games
  .filter(game => !notAllowedProviders.includes(game.provider.id));

export const getCategoryById = (categories, categoryId) => categories?.items
  ?.some(category => category.id === categoryId);

export const isFavoriteCategory = categories => getCategoryById(
  categories,
  FAVORITE_GAMES_CATEGORY_ID,
);

export const attachFavoriteGamesToCategories = (categories, favoriteGamesCat) => {
  if (categories.items.length) {
    return [...categories.items, favoriteGamesCat];
  }
  return categories;
};

export const removeSubstrings = (input, substrings) => {
  let formattedInput = input;

  substrings.forEach((substring) => {
    formattedInput = formattedInput.replace(substring, '');
  });
  return formattedInput;
};

export const formatForSlugSearch = (searchInput) => {
  const substringsToBeRemoved = [' &', ' and', "'"];
  return removeSubstrings(searchInput, substringsToBeRemoved);
};

export const extendProvidersWithStudios = providersList => (extendValue) => {
  if (extendValue && providersList?.length) {
    return (
      providersList.reduce((allProvider, provider) => {
        if (provider.studios?.length && extendValue in provider) {
          const gameStudios = provider.studios.map(studio => ({
            ...studio,
            [extendValue]: provider[extendValue],
            status: provider.status,
          }))
            .filter((studio) => {
              // If the gameStudio has no slug, then it has't shown in the provider modal
              if (extendValue === 'domains_whitelist') {
                return !!studio.slug;
              }
              return true;
            });

          return [
            ...allProvider,
            { ...provider, type: 'provider' },
            ...gameStudios,
          ];
        }

        return [...allProvider, { ...provider, type: 'provider' }];
      }, [])
      || []
    );
  }

  return providersList;
};