import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const FooterMessage = ({ children }) => {
  const { t } = useTranslation('common');
  return (
    <>
      {children}
      <p className="has-text-centered has-text-grey m-modal-external-message">
        <i className="icon-18-plus is-size-3" />
        <small>
          {t('responsible_gaming_message_part_one')}
          <br className="is-hidden-mobile" />
          <span className="is-hidden-mobile">&nbsp;</span>
          {t('responsible_gaming_message_part_two')}
        </small>
      </p>
    </>
  );
};

FooterMessage.propTypes = {
  children: PropTypes.node,
};

FooterMessage.defaultProps = {
  children: null,
};

export default FooterMessage;
