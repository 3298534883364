import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import PromotionWidgetWrapper from '../Promotion/PromotionWidgetWrapper';
import {
  formatLocalizedDate,
  getPathname,
  stripHtml,
  truncate,
} from '../../Helpers';
import WidgetThumbnail from '../WidgetThumbnail';

export const HeroPromotionWidgetItem = ({ data }) => {
  const excerpt = data.excerpt ? stripHtml(data.excerpt) : truncate(stripHtml(data.body), 20);

  return (
    <NavLink to={getPathname(data.meta.html_url)} className="level is-mobile m-promo-widget-item">
      <WidgetThumbnail alt={data.title} thumbnail={data.thumbnail} />
      <div className="level-item">
        <div className="m-promo-widget-item-text">
          <div className="level is-marginless is-size-7 has-text-grey-light">
            <div className="level-left" />
            <div className="level-right">
              {formatLocalizedDate(data.valid_from, 'D MMM YYYY')}
            </div>
          </div>
          <h3 className="has-text-white m-promo-widget-title-hero">{excerpt}</h3>
        </div>
      </div>
    </NavLink>
  );
};

HeroPromotionWidgetItem.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export const HeroPromotionWidget = ({ items, linkTo }) => {
  const { t } = useTranslation();

  let index = 0;
  const promotions = items.map((item) => {
    index += 1;
    return <HeroPromotionWidgetItem key={index} data={item} />;
  });

  return (
    <div className="card m-user-dashboard-card m-promo-widget content has-background-gradient-neon has-border-light">
      <div className="card-title">
        <h4 className="has-text-white is-size-6-mobile has-text-centered-mobile">{`${t('common:promo_calendar')}:`}</h4>
      </div>
      <div className="card-content content">
        {promotions}
        <p className="has-text-right">
          <NavLink to={getPathname(linkTo)} className="has-text-white">{t('common:all_upcoming_events')}</NavLink>
        </p>
      </div>
    </div>
  );
};

HeroPromotionWidget.propTypes = {
  items: PropTypes.instanceOf(Array),
  linkTo: PropTypes.string.isRequired,
};

HeroPromotionWidget.defaultProps = {
  items: [],
};

const HeroPromotions = ({ featuredPromotion }) => (
  <PromotionWidgetWrapper id={featuredPromotion.id}>
    {({ items }) => (
      <HeroPromotionWidget items={items} linkTo={featuredPromotion.html_url} />
    )}
  </PromotionWidgetWrapper>
);

HeroPromotions.propTypes = {
  featuredPromotion: PropTypes.instanceOf(Object).isRequired,
};

export default HeroPromotions;
