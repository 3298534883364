import React from 'react';
import { useDispatch } from 'react-redux';
import { initCategoriesAndProviders } from '../../redux/actions/gamesActions';

function useCategoriesAndProviders(search) {
  const dispatch = useDispatch();

  const params = new URLSearchParams(search);

  const countryPreview = params.get('country_preview')
    ? params.get('country_preview').toUpperCase()
    : '';

  React.useEffect(() => {
    dispatch(initCategoriesAndProviders(countryPreview));
  }, [dispatch, countryPreview]);

  return null;
}

export default useCategoriesAndProviders;
