import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { isHomePage } from '../../Helpers';
import CategoryWinnersList from '../CategoryWinnersLists';
import GameThumbnailList from '../GameThumbnailList';
import useGames from '../../../hooks/games/useGames';


function GamesByCategories({ categories }) {
  const { gamesByCategories } = useGames();
  const location = useLocation();

  return (
    <>
      {Object.values(categories)
        .sort((a, b) => a.position - b.position)
        .map((item, idx) => (
          <React.Fragment key={item?.id}>
            {!!gamesByCategories[item?.id]?.games?.length && (
              <GameThumbnailList
                key={item.id}
                category={item}
                maxLimit={item.displayGamesCount}
              />
            )}
            {idx === 1 &&
              isHomePage(location.pathname) &&
              process.env.REACT_APP_SHOW_TOURNAMENTS_CARDS === 'true' && (
                <CategoryWinnersList />
              )}
          </React.Fragment>
        ))}
    </>
  );
}

export default GamesByCategories;

GamesByCategories.propTypes = {
  categories: PropTypes.instanceOf(Object).isRequired,
};
