import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useQuery from '../useQuery';
import useGames from './useGames';

function useFavoriteGamesController() {
  const { t } = useTranslation();
  const { query, removeQuery } = useQuery();
  const { favoriteGames } = useGames();

  useEffect(() => {
    const favoriteGamesCategory = query.get('category');
    if (
      favoriteGamesCategory === t('common:favorite_games_slug')
      && !favoriteGames.games.length
    ) {
      removeQuery('category');
    }
  }, [query, removeQuery, t, favoriteGames.games.length]);

  return null;
}

export default useFavoriteGamesController;