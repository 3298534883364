import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toggleModalReset } from '../../redux/actions/passwordResetActions';
import { toggleModal } from '../../redux/actions/signUpModalActions';
import TimeOut from '../App/timeOut';
import { clipHTMLBody } from '../../components/Helpers';
import EGASS from '../EGASS/index';

class ModalRenderer extends Component {
  componentDidMount() {
    const { props } = this;
    const { location } = this.props;
    const { search, pathname } = location;
    const egas = new EGASS();
    const queryParams = new URLSearchParams(search);
    const sessionHandler = new TimeOut();
    switch (pathname) {
      case '/password/reset': {
        const parameters = {
          email: queryParams.get('email'),
          confirmationCode: queryParams.get('confirmationCode'),
        };
        props.toggleModalReset(parameters);
        break;
      }
      case '/logout':
        sessionHandler.timeOut();
        break;
      case '/signup':
        egas.call(location);
        props.toggleModal();
        clipHTMLBody();
        break;
      case '/signup/':
        egas.call(location);
        props.toggleModal();
        clipHTMLBody();
        break;
      default:
        break;
    }
  }

  render() {
    const { history } = this.props;

    history.push('/');
    return 1;
  }
}

ModalRenderer.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf([]).isRequired,
  toggleModalReset: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export const mapDispatchToProps = dispatch => ({
  toggleModalReset: queryParams => dispatch(toggleModalReset(queryParams)),
  toggleModal: () => dispatch(toggleModal()),
});

export default compose(
  connect(null, mapDispatchToProps),
  withTranslation(),
  withRouter,
)(ModalRenderer);
