import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import validate from '../../../containers/utitilies/validator_resend_confirmation';
import { RenderField } from '../../Layout/Fields/renderFields';
import { SignupThirdStep } from '../Signup/SignupForm';
import MandatoryField from '../../Layout/Fields/MandatoryField';

export const ResendVerificationCodeModal = ({
  isOpen,
  message,
  success,
  submitResendCode,
  handleUsernameChange,
  toggleModal,
  pristine,
  invalid,
  loading,
  username,
  onVerification,
  requestNewCode,
  verificationType,
}) => {
  const { t } = useTranslation();

  return (
    <div
      id="modal-password-reset"
      className={`modal m-modal-password-reset modal-fx-superScaled ${isOpen ? 'is-active' : ''}`}
    >
      <div className="modal-background" />
      <div className="modal-card modal-content">
        <header className="modal-card-head">
          <div className="modal-card-title">
            <h2 className="is-size-3 is-size-4-mobile has-text-white has-text-centered">
              {success ? t('common:enter_verification_code') : t('common:confirmation_resend_header')}
            </h2>
          </div>
          <button
            type="button"
            className="delete"
            aria-label="close"
            onClick={toggleModal}
          />
        </header>
        <section className="modal-card-body">
          {!success
            ? (
              <>
                <h3 className="is-size-4">
                  {t('common:confirmation_resend_title')}
                </h3>
                <fieldset className="m-fieldset">
                  {message && (
                  <div className="notification is-danger content">
                    {message}
                  </div>
                  )}
                  <legend className="m-legend is-size-6 is-size-6-mobile">
                    {t('common:confirmation_resend_text')}
                  </legend>
                  <MandatoryField />
                  <div className="columns is-multiline">
                    <div className="column is-tablet field m-account-auto-margin">
                      <div className="control has-icons-left">
                        <Field
                          className="input is-medium"
                          id="username"
                          name="username"
                          icon="fa-user"
                          required="required"
                          type="text"
                          component={RenderField}
                          label={t('fields:username')}
                          onChange={handleUsernameChange}
                        />
                      </div>
                    </div>
                  </div>
                </fieldset>
              </>
            )
            : (
              <SignupThirdStep
                email={null}
                username={username}
                onVerification={onVerification}
                requestNewCode={requestNewCode}
                verificationType={verificationType}
              />
            )}
        </section>
        <footer className="modal-card-foot">
          <div className="columns is-centered is-full-width">
            <div className="column is-4-tablet is-hidden-mobile">
              <button
                type="button"
                id="resend-confirmation-email"
                className="button button-modal-close has-background-light is-medium is-full-width"
                onClick={toggleModal}
              >
                <i className="icon-close" />
                &nbsp;&nbsp;
                {t('buttons:close').toUpperCase()}
                &nbsp;&nbsp;
              </button>
            </div>
            {!success
            && (
            <div className="column is-6-tablet has-text-centered">
              <button
                type="button"
                disabled={pristine || invalid}
                className={`button is-success is-medium is-full-width ${loading ? 'is-loading' : ''}`}
                onClick={submitResendCode}
              >
                <span>{t('buttons:resendConfirmation')}</span>
                <span className="icon is-small">
                  <i className="fas fa-arrow-right" />
                </span>
              </button>
            </div>
            )}
          </div>
        </footer>
      </div>
    </div>
  );
};

ResendVerificationCodeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  success: PropTypes.bool.isRequired,
  submitResendCode: PropTypes.func.isRequired,
  handleUsernameChange: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired,
  onVerification: PropTypes.func.isRequired,
  requestNewCode: PropTypes.func.isRequired,
  verificationType: PropTypes.string.isRequired,
};

export default reduxForm({
  form: 'resetResendForm',
  validate,
})(ResendVerificationCodeModal);
