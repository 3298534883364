import React, { PureComponent } from 'react';
import { Cookies } from 'react-cookie';
import PropTypes from 'prop-types';

class SimpleBonusSlider extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      bonuses: [],
    };
    this.cookieValues = new Cookies().get(process.env.REACT_APP_API_COOKIE_NAME);
  }

  componentDidMount() {
    if (this.cookieValues !== undefined) {
      this.initBonuses();
    }
  }

  initBonuses = () => {
    const { availableBonuses } = this.props;
    const bonuses = availableBonuses;

    this.setState({ bonuses });
  };

  handleOptOut = (e) => {
    e.preventDefault();
    const { onClose } = this.props;
    this.setState({
      bonuses: [],
    });
    onClose();
    return true;
  };

  render() {
    const { t, i18n } = this.props;
    const { bonuses } = this.state;
    const isoLanguage = i18n.language.split('-')[0];
    let parsedDescription = '';
    if (bonuses.length > 0) {
      parsedDescription = JSON.parse(bonuses[0].description);
    }

    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <div ref={this.bonusSliderContainer} id="bonusSliderContainer" className={`m-bonus-slider m-bonus-slider-simple has-text-centered has-text-dark ${(bonuses.length > 0) ? 'is-active' : 'is-inactive'}`}>
        {bonuses.length > 0 && (
          <>
            <div>
              <h2 className="m-bonus-slider-simple__title">{ bonuses[0].bonusPlanName }</h2>
              <p>{ parsedDescription[0][isoLanguage].Description }</p>
            </div>
            <div>
              <p style={{ fontWeight: '100', paddingTop: '10px', paddingBottom: '10px' }}>
                {t('account:2nd_3rd_deposit_slider_text')}
                {' '}
                <span style={{ fontWeight: '700' }}>
                  { bonuses[0].bonusPlanId }
                </span>
              </p>
            </div>
          </>
        )}
        <div className="level-left" style={{ justifyContent: 'center', paddingBottom: '10px' }}>
          <p className="has-text-weight-light">
            <a target="_blank" href={t('common:bonus_rules_link')} rel="noopener noreferrer">
              <u>{t('account:bonus_slider_bonus_terms')}</u>
            </a>
          </p>
          <p className="has-text-weight-light">
            <a href="/#" id="delete" onClick={this.handleOptOut}>
              <u>{t('account:continue_without_bonus')}</u>
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default SimpleBonusSlider;

SimpleBonusSlider.propTypes = {
  availableBonuses: PropTypes.instanceOf(Array).isRequired,
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.instanceOf(Object).isRequired,
};
