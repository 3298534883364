import CMSApi from '../CMSApi';

class GamesApi extends CMSApi {
  constructor(baseUrl, authToken) {
    super(baseUrl, authToken);
    this.baseUrl = baseUrl;
  }
}

export default new GamesApi(
  process.env.REACT_APP_API_BASE_URL,
  process.env.REACT_APP_CMS_AUTHORIZATION,
);
