import React from 'react';
import { context } from '@dialinvest/react-casino';
import { useLocation } from 'react-router-dom';
import useQuery from '../useQuery';
import useGames from './useGames';

function useActiveGames() {
  const { query } = useQuery();
  const { categories, providers, activeGame } = useGames();
  const { pathname } = useLocation();
  const { pageType } = React.useContext(context.PageContext);

  const initialCategory = query.get('category');
  let initialProvider = query.get('provider');
  const initialStudio = query.get('studio');

  if (pageType === 'GameProviderPage') {
    const [, , currentProvider] = pathname.split('/');
    initialProvider = currentProvider;
  }

  const activeCategory = categories.items
    ?.find(category => category.slug === initialCategory) || null;

  const activeProvider = providers.items
    ?.find(provider => (
      provider.slug === initialProvider
    )) || null;

  const activeStudio = providers.items
    ?.find(provider => (
      provider.slug === initialStudio
    )) || null;

  return {
    activeCategory, activeProvider, activeStudio, activeGame,
  };
}

export default useActiveGames;
