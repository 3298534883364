import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BasePage from '../Base';
import Banner from '../../Layout/Banner';

class KnowledgePage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      active: null,
    };
    this.active = '';
  }

  setActive = (e, active) => {
    const { state } = this;
    e.preventDefault();
    (state.active === active) ? this.active = '' : this.active = active;
    this.setState({ active: this.active });
  };

  render() {
    const { data } = this.props;
    const { active } = this.state;

    return (
      <>
        <BasePage data={data}>
          <Banner data={data} />
          <section className="section m-page-wrapper">
            <div className="container is-fluid">
              <div className="columns is-multiline is-centered">
                <div className="column is-12-mobile is-12-tablet is-9-widescreen">
                  <div className="content typography">
                    <h1>{ data.title }</h1>
                    <p><strong style={{ color: 'white' }}>{ data.description }</strong></p>
                  </div>
                  { data.questions_and_answers.map(item => (
                    <section key={item} className="accordions m-accordions">
                      <div className="content typography">
                        <h2>{ item.heading }</h2>
                      </div>
                      { item.questions.map(qitem => (
                        <article key={qitem.question} className={`accordion ${active === qitem.question ? 'is-active' : ''}`}>
                          <div
                            id={qitem.question}
                            className="accordion-header toggle"
                            aria-label="toggle"
                            onClick={e => this.setActive(e, qitem.question)}
                          >
                            <h2>
                              <strong>
                                { qitem.question }
                              </strong>
                              <i className="icon-arrow-down accordion-arrow" />
                            </h2>
                          </div>
                          <div className="accordion-body">
                            <div className="accordion-content content typography">
                              <div dangerouslySetInnerHTML={{ __html: qitem.answer || '' }} />
                            </div>
                          </div>
                        </article>
                      ))}
                    </section>
                  ))}
                  <br />
                </div>
              </div>
            </div>
          </section>
        </BasePage>
      </>
    );
  }
}

KnowledgePage.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    banner: PropTypes.instanceOf(Object),
    body: PropTypes.string.isRequired,
  }),
};

KnowledgePage.defaultProps = {
  data: {},
};

export default KnowledgePage;
