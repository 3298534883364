import React from "react";
import Casino from "@dialinvest/react-casino";
import { useTranslation } from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Loader from "react-loader-spinner";
import { Carousel } from "react-responsive-carousel";

import { changeUserNameWithStarWinners } from "../../Helpers";
import { isoToMark } from "../../Page/TournamentsPage/useGetPrize";
import { getPlayAndGoGamesById } from "../CategoryWinnersLists";
import { Link } from "react-router-dom";

const TopWinnersCard = () => {
  const carousel = React.useRef(null);

  const { t } = useTranslation();
  const [topWinners, setTopWinners] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    let active = true;
    setLoading(true);
    const getGameWinnerList = async () => {
      try {
        let topWinnersList;

        const parameters = {
          domain: `https://${process.env.REACT_APP_FRONTEND_URL}`,
          period: 32,
          size: 50,
          periodType: "daily",
          uniqueWinner: true,
        };

        topWinnersList = await new Casino.GetWinners(parameters).perform();

        if (!topWinnersList.length) {
          delete parameters.periodType;
          topWinnersList = await new Casino.GetWinners(parameters).perform();
        }

        const missingGameIdArray = [];
        let normalizedLatestWinners;

        topWinnersList.forEach(
          (item) => !item.thumbnail && missingGameIdArray.push(item.gameId)
        );

        if (missingGameIdArray.length > 0) {
          const missingGamesData = await getPlayAndGoGamesById(
            missingGameIdArray
          );

          normalizedLatestWinners = topWinnersList.map((item) => {
            let newItem = { ...item, sortableAmount: item.amountWon };

            if (!newItem.thumbnail) {
              const missingGame = missingGamesData.find(
                (game) => game.reference === newItem.gameId
              );
              if (missingGame) {
                newItem.thumbnail = missingGame.thumbnail;
                newItem.slug = missingGame.slug;
              }
            }

            if (newItem.sortableAmount.includes(",")) {
              const correctedItem = newItem.sortableAmount.replace(/,/g, "");
              newItem.sortableAmount = correctedItem;
            }

            return newItem;
          });

          if (active) {
            setTopWinners(normalizedLatestWinners);
          }
          return;
        }

        if (active) {
          setTopWinners(topWinnersList);
        }
      } catch (error) {
        console.log(error);
      } finally {
        if (active) {
          setLoading(false);
        }
      }
    };
    if (active) {
      getGameWinnerList();
    }
    return () => {
      active = false;
    };
  }, []);

  /* istanbul ignore next */
  const onSlide = (currentItem) => {
    if (currentItem > topWinners.length - 7) {
      carousel.current.moveTo(0);
    }
  };

  return (
    <section
      key="topWinnerList"
      className="column m-winnerslist-card m-winnerslist-card__latest has-text-white has-text-centered is-active"
    >
      <div className="m-winnerslist-card__container p-1">
        <div className="m-winnerslist-card__header">
          <div className="m-winnerslist-card__maintitle">
            <h2 className="m-winnerslist-card__title is-size-6 has-text-weight-bold">
              <img
                src="/images/winners-widget/title-top-winners.png"
                alt={t("common:top_winners_list_title")}
                loading="lazy"
              />
              <span className="is-sr-only">
                {t("common:top_winners_list_title")}
              </span>
            </h2>
          </div>
        </div>
        {loading ? (
          <div
            style={{
              height: "80%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader color="#552c88" type="ThreeDots" />
          </div>
        ) : (
          <>
            <div className="m-winnerslist-card__rankingsplatform">
              <Carousel
                autoPlay={topWinners.length > 6 && true}
                axis="vertical"
                dynamicHeight={false}
                showThumbs={false}
                showArrows={false}
                showStatus={false}
                showIndicators={false}
                ref={carousel}
                onChange={onSlide}
                interval={1000}
                infiniteLoop={true}
                stopOnHover={false}
              >
                {!topWinners.length || loading
                  ? null
                  : topWinners?.map((player, index) => (
                      <div
                        key={`${index}_${player.playerName}`}
                        className="m-winnerslist-card__rankingsplatform-item"
                      >
                        <div className="item-container">
                          <div className="m-winnerslist-card__rankingsplatform-item-user is-size-7-mobile p-2 has-text-weight-bold">
                            {changeUserNameWithStarWinners(player.playerName)}
                          </div>
                          <div className="m-winnerslist-card__rankingsplatform-item-score is-size-7-mobile has-text-weight-bold">
                            {isoToMark(player.currency, player.amountWon)}
                          </div>
                          <div className="m-winnerslist-card__rankingsplatform-item-image ">
                            <figure>
                              <img
                                src={player.thumbnail?.url}
                                data-flickity-lazyload={player.thumbnail?.url}
                                alt=""
                              />
                            </figure>
                          </div>
                        </div>
                      </div>
                    ))}
              </Carousel>
            </div>
          </>
        )}
        <div className="m-winnerslist-card__rankingsplatform-button">
          <Link
            to="/en-int/blog#winner-stories"
            className="has-text-weight-bold"
          >
            {t("buttons:check_our_big_wins")}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default TopWinnersCard;
