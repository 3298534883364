import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ProviderLogo from '../../Game/GameThumbnail/GameProviderLogo';
import useGames from '../../../hooks/games/useGames'

const GamesWidget = ({
  game,
}) => {
  const { t, i18n } = useTranslation();
  const image = 'http://placehold.it/450x300';
  const { providers } = useGames();

  const slug = game !== undefined ? game?.slug : '';
  const supportsDemoPlay = game !== undefined ? game?.supports_demo_play : false;
  const srcImage = game !== undefined ? game?.thumbnail.url : image;
  const status = game !== undefined ? game?.status : false;
  const provider = game !== undefined ? game?.provider : '';

  const createUrl = demoPlay => ({
    pathname: `/${i18n.language}/games/${slug}/`,
    search: !demoPlay ? '?mode=real' : '',
    state: {
      supportsDemoPlay,
    },
  });

  const GameNavLink = () => (
    <>
      <ProviderLogo providerList={providers.items} providerName={provider.name} />

      <Link key={`${slug}real`} to={createUrl(false)} className="button is-dark-green">
        { t('buttons:play_for_real') }
      </Link>
      {supportsDemoPlay
      && (
        <Link key={`${slug}free`} to={createUrl(true)} className="button-play-for-free">
          { t('buttons:play_for_free') }
        </Link>
      )}
    </>
  );

  const GameMaintenance = () => (
    <div className="button is-danger is-maintenance">
      <i className="icon-maintenance is-size-3" />
      <br />
      <p>{ t('buttons:under_maintenance') }</p>
    </div>
  );

  return (
    <>
      {game !== undefined
      &&
      <div>
        <img className="m-carousel-game-thumb" src={srcImage} alt="" />
        <div className="m-game-grid-item__shadow" />
        <div className="m-game-grid-buttons is-flex">
          {status === 'enabled' ? GameNavLink() : GameMaintenance()}
        </div>
      </div>
      }
    </>
  )
};

GamesWidget.propTypes = {
  game: PropTypes.instanceOf(Object).isRequired,
};



export default GamesWidget;
