import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useQuery from '../../../hooks/useQuery';
import useActiveGames from '../../../hooks/games/useActiveGames';

const GameProviderModal = ({ menuItems, menuButton }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { t } = useTranslation('common');
  const { activeProvider, activeStudio } = useActiveGames();
  const { query, addQuery, removeQuery, replaceQuery } = useQuery();

  const providerQuery = query.get('provider');

  const filteredItems = menuItems?.filter(item => item?.name
    ?.toLowerCase().includes(searchTerm.toLowerCase())
  || item?.display_name?.toLowerCase().includes(searchTerm.toLowerCase()));

  const handleSearch = (event) => {
    setSearchTerm(event?.target?.value);
  };

  function handleClick(id, providerSlug, type) {
    const queryType = type === 'studio' ? 'studio' : 'provider';
    const queryTypeToRemove = type === 'studio' ? 'provider' : 'studio';
    if (providerSlug === activeProvider?.slug || providerSlug === activeStudio?.slug) {
      removeQuery(queryType);
    } else if (
      (activeProvider && type === 'studio')
      || (activeStudio && type === 'provider')
    ) {
      replaceQuery(queryTypeToRemove, queryType, providerSlug);
    } else {
      addQuery(queryType, providerSlug);
    }
  }

  const closeModal = (e) => {
    if (e.target.classList.contains('m-game-provider-modal') || e.target.classList.contains('delete') || e.target.closest('.m-game-provider-button')) {
      menuButton.current.click();
      setSearchTerm('');
    }
  };

  return (
    <>
      <div
        id="gameProviderModal"
        aria-modal="true"
        aria-hidden="true"
        onClick={closeModal}
        className="m-game-provider-modal"
      >
        <div className="m-game-provider-modal__content">
          <button
            onClick={closeModal}
            type="button"
            className="delete has-background-primary"
          >
            <span className="sr-only">close</span>
          </button>
          <p className="control has-icons-left has-icons-right">
            <input
              className="input"
              value={searchTerm}
              onChange={handleSearch}
              id="gameProvidersList"
              type="text"
              placeholder="Search"
            />
            <span className="icon is-small is-left has-text-primary">
              <i className="fas fa-search has-text-primary" />
            </span>
          </p>
          <div className="m-game-provider-modal__list" id="gameProvidersList">
            <button
              type="button"
              className={`m-game-provider-button all ${
                !activeProvider && !activeStudio ? 'is-active' : ''
              }`}
              onClick={() => removeQuery(providerQuery ? 'provider' : 'studio')}
              key={99999}
              title={t('all_providers')}
            >
              <div className="m-game-provider-button__content">
                {t('all_providers')}
                &nbsp;
                {menuItems?.length}
              </div>
            </button>
            {filteredItems?.map((item) => (
              <button
                type="button"
                id={`navbar-item-${item.id}`}
                onClick={() => handleClick(item.id, item.slug, item.type)}
                key={item.id + item.display_name + item.name}
                className={`m-game-provider-button ${
                  activeProvider?.slug === item.slug ||
                  activeStudio?.slug === item.slug
                    ? 'is-active'
                    : ''
                }`}
                title={item.display_name === '' ? item.name : item.display_name}
              >
                <div className="m-game-provider-button__content">
                  {item?.logo?.meta?.download_url ? (
                    <img
                      src={item?.logo?.meta?.download_url}
                      alt={
                        item.display_name === '' ? item.name : item.display_name
                      }
                    />
                  ) : (
                    <p>
                      {item.display_name === '' ? item.name : item.display_name}
                    </p>
                  )}
                </div>
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

GameProviderModal.propTypes = {
  menuItems: PropTypes.instanceOf(Array),
  menuButton: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
};

GameProviderModal.defaultProps = {
  menuItems: [],
};

export default GameProviderModal;
