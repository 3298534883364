/* eslint-disable import/prefer-default-export */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const IframeHelper = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.top.location.pathname.includes('game') && window.top.location.pathname !== window.location.pathname) {
      window.top.location.href = window.location.href;
    }
  }, [location]);

  return null;
};
