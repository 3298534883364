import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const MandatoryField = ({ text }) => {
  const { t } = useTranslation('fields');

  return (
    <div className="level is-mobile has-text-primary">
      <div className="level-left m-alert-message">
        <span className="icon is-medium">
          <span className="fa-stack">
            <i className="fas fa-circle fa-stack-2x" />
            <i className="fas fa-info fa-stack-1x fa-inverse has-text-white" />
          </span>
        </span>
        &nbsp;&nbsp;
        <span>
          { t(text) }
        </span>
      </div>
    </div>
  );
};

MandatoryField.propTypes = {
  text: PropTypes.string,
};

MandatoryField.defaultProps = {
  text: 'all_fields_mandatory',
};

export default MandatoryField;
