import React from 'react';
import PropTypes from 'prop-types';
import TournamentsWidgetList from './TournamentsWidgetList';

const TournamentsPageCalendar = ({
  cookieValues,
  active,
  items,
  onSignupAction,
  menuID,
  t,
}) => (
  <>
    <section className="section m-tournament-main">
      <div className="columns is-tablet">
        <div className="column is-12-mobile">
          <div
            className="m-tournaments-main-subtitle has-background-color-linear tournaments-subtitle is-size-4 is-size-6-mobile has-text-weight-bold"
          >
            {t('common:tournament_calendar')}
          </div>

          <div className="content my-6 ml-4 is-size-5 color-primary">
            <p>
              {t('common:tournament_upcoming')}
            </p>
          </div>

          <TournamentsWidgetList cookieValues={cookieValues} active={active} items={items} onSignupAction={onSignupAction} menuID={menuID} />
        </div>

      </div>
    </section>
  </>
);

TournamentsPageCalendar.propTypes = {
  cookieValues: PropTypes.string,
  active: PropTypes.string,
  items: PropTypes.instanceOf(Array),
  onSignupAction: PropTypes.func.isRequired,
  menuID: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

TournamentsPageCalendar.defaultProps = {
  cookieValues: '',
  active: '',
  items: [],
  games: [],
};

export default TournamentsPageCalendar;
