import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const ResendSuccessModal = ({
  isOpen,
  toggleModal,
}) => {
  const { t } = useTranslation();

  return (
    <div
      id="modal-password-reset"
      className={`modal m-modal-password-reset modal-fx-superScaled ${isOpen ? 'is-active' : ''}`}
    >
      <div className="modal-background" />
      <div className="modal-card modal-content">
        <header className="modal-card-head">
          <div className="modal-card-title">
            <h2 className="is-size-3 is-size-4-mobile has-text-white has-text-centered">
              {t('common:confirmation_resend_header')}
            </h2>
          </div>
          <button
            type="button"
            className="delete"
            aria-label="close"
            onClick={toggleModal}
          />
        </header>
        <section className="modal-card-body">
          <fieldset className="m-fieldset">
            <div className="notification is-success content">
              {t('common:resend_code_success')}
            </div>
          </fieldset>
        </section>
        <footer className="modal-card-foot">
          <div className="columns is-centered is-full-width">
            <div className="column is-4-tablet is-hidden-mobile">
              <button
                type="button"
                id="resend-confirmation-email"
                className="button button-modal-close has-background-light is-medium is-full-width"
                onClick={toggleModal}
              >
                <i className="icon-close" />
                &nbsp;&nbsp;
                {t('buttons:close').toUpperCase()}
                &nbsp;&nbsp;
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

ResendSuccessModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default ResendSuccessModal;
