export const SET_USER_STATE = 'SET_USER_STATE';
export const SET_USER_SESSION = 'SET_USER_SESSION';
export const SET_USER_DATA = 'SET_USER_DATA';
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';

export function setUserLoggedIn(newState) {
  return {
    type: SET_USER_STATE,
    newState,
  };
}

export function setUserData(payload) {
  return {
    type: SET_USER_DATA,
    payload,
  };
}

export function clearUserData() {
  return {
    type: SET_USER_DATA,
  };
}
