import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getLocalTime } from '../../Helpers';

const TimeWidget = ({
  start,
  end,
  menuID,
  active,
  id,
}) => {
  const { i18n, t } = useTranslation();

  const [timeLeft, setTimeLeft] = useState({});
  const [isTimeFieldHidden, setIsTimeFieldHidden] = useState(true);

  const localStartTime = getLocalTime(start, i18n.language);
  const localEndTime = getLocalTime(end, i18n.language);
  const timerComponents = [];

  const calculateTimeLeft = () => {
    const now = new Date();
    let difference;
    if (menuID === '1') {
      active = id;
    }
    if (menuID === '2') {
      difference = +new Date(start) - +new Date(now);
    } else {
      difference = +new Date(end) - +new Date(now);
    }

    if (difference > 0) {
      return {
        [t('common:tournament_days')]: Math.floor(difference / (1000 * 60 * 60 * 24)),
        [t('common:tournament_hours')]: Math.floor((difference / (1000 * 60 * 60)) % 24),
        [t('common:tournament_minutes')]: Math.floor((difference / 1000 / 60) % 60),
        [t('common:tournament_seconds')]: Math.floor((difference / 1000) % 60),
      };
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
      setIsTimeFieldHidden(false);
    }, 1000);
    return () => { clearTimeout(setTimeLeft); };
  });

  if (timeLeft) {
    Object.keys(timeLeft).forEach((interval) => {
      if (!timeLeft[interval]) {
        return;
      }

      timerComponents.push(
        <span key={`time-${interval}`}>
          {timeLeft[interval]}
          {' '}
          {interval}
          {' '}
        </span>,
      );
    });
  }

  return (
    <>
      <div className="table-container">
        <table className="table m-auto">
          <tr className="is-flex is-size-6-mobile is-size-6">
            <td style={{ width: 'auto' }}>
              {menuID === '2'
                ? t('common:tournament_starts_on')
                : t('common:tournament_started')}
            </td>
            {menuID === '2'
              ? (
                <td style={{ width: 'auto' }} className={`m-end-date ${isTimeFieldHidden ? 'time-opacity' : ''}`}>
                  {timerComponents.length ? timerComponents : t('common:tournament_time_has_started')}
                </td>
              )
              : (
                <td style={{ width: 'auto' }} className="m-start-date is-size-6-mobile is-size-6 has-text-right">
                  {localStartTime}
                </td>
              )}
          </tr>
          <tr className="is-flex is-size-6-mobile is-size-6">
            <td style={{ width: 'auto' }}>
              {menuID === '3'
                ? t('common:tournament_ended')
                : t('common:tournament_ends_in')}
            </td>
            {menuID === '2' || menuID === '3'
              ? (
                <td style={{ width: 'auto' }} className="m-start-date is-size-6-mobile is-size-6 has-text-right">
                  {localEndTime}
                </td>
              )
              : (
                <td style={{ width: 'auto' }} className={`m-end-date ${isTimeFieldHidden ? 'time-opacity' : ''}`}>
                  {timerComponents.length ? timerComponents : t('common:tournament_time_is_up')}
                </td>
              )}
          </tr>
        </table>
      </div>
    </>
  );
};

TimeWidget.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  menuID: PropTypes.string.isRequired,

};

export default TimeWidget;
