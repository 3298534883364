import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Image = (
  {
    src,
    alt,
    children,
    width,
    height,
    timeOut,
    customClass,
  },
) => {
  const [loaded, setLoaded] = useState(false);

  const onLoad = () => {
    setTimeout(() => setLoaded(true), timeOut);
  };

  const placeholder = () => children || <div className="placeholder-image" style={{ height, width }} />;

  return (
    <>
      {!loaded && placeholder()}
      <img className={customClass} src={src} alt={alt} style={loaded ? {} : { display: 'none' }} onLoad={onLoad} width={width} />
    </>
  );
};

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.string,
  children: PropTypes.node,
  height: PropTypes.string,
  timeOut: PropTypes.number,
  customClass: PropTypes.string,
};

Image.defaultProps = {
  src: null,
  alt: '',
  width: null,
  height: null,
  timeOut: 1,
  customClass: null,
  children: null,
};

export default Image;
