import React from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import {
  localeToCurrencyIso,
} from '../../../containers/utitilies/currencies';

export const isoToMark = (iso, prize) => {
  switch (iso) {
    case 'FS':
      return `FS ${prize}`;
    case 'CAD':
      return `$${prize}`;
    case 'EUR':
      return `€${prize}`;
    case 'ZAR':
      return `R${prize}`;
    case 'CHF':
      return `Fr ${prize}`;
    case 'PEN':
      return `${prize} PEN`;
    case 'PLN':
      return `${prize} PLN`;
    case 'MXN':
      return `${prize} MXN`;
    case 'BRL':
      return `${prize} BRL`;
    case 'NOK':
      return `${prize} NOK`;
    default:
      return prize;
  }
};

function checkFreeSpinsPrize(prizes) {
  if ('prize_currencies' in prizes && Array.isArray(prizes?.prize_currencies)) {
    return prizes?.prize_currencies.reduce(
      (result, prize) => (prize.currency === 'FS'
        ? { ...result, isFreeSpins: true, prize }
        : { ...result, prize }),
      { isFreeSpins: false },
    );
  }
  return { isFreeSpins: false, prize: null };
}

function useGetPrize() {
  const lang = i18next.language;
  const userSession = useSelector(state => state?.userState);

  const getPrize = React.useCallback((prizes) => {
    const { isFreeSpins, prize: p } = checkFreeSpinsPrize(prizes);

    if (isFreeSpins) return isoToMark(p?.currency, p?.prize);

    if (userSession?.userLoggedIn) {
      let iso = userSession?.currency;

      const prize = prizes?.prize_currencies?.find(
        curr => curr.currency === iso,
      );

      if (!prize) {
        iso = localeToCurrencyIso[lang];
      }
      const realPrize = prizes?.prize_currencies?.find(curr => curr.currency === iso)
        || prizes?.prize_currencies[0];

      return isoToMark(iso, realPrize?.prize);
    }
    const iso = localeToCurrencyIso[lang] || localeToCurrencyIso['en-int'];
    const prize = prizes?.prize_currencies?.find(curr => curr.currency === iso);
    if (!prize) {
      const realPrize = prizes?.prize_currencies[0];
      return realPrize.prize;
    }

    return isoToMark(iso, prize?.prize);
  }, [lang, userSession]);

  return { getPrize };
}

export default useGetPrize;
