import React, { useEffect, Suspense, lazy } from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';
import { context } from '@dialinvest/react-casino';
import { useSelector } from 'react-redux';
import PageRenderer from '../PageRenderer';
import ModalRenderer from '../ModalRenderer';
import AppRoute from './AppRoute';
import ScrollToTop from '../../components/Layout/ScrollToTop';
import Page404 from '../../components/Page404';
import PageLoader from '../../components/PageLoader';
import { anyConsentToAccept } from '../../components/Helpers';
import BlogCategoryWrapper from '../../components/Page/Blog/BlogCategoryWrapper';
import ProtectedRoute from './ProtectedRoute';
import { surveyConfig } from '../../components/Page/SourceOfWealth/surveyConfig';

const GamePage = lazy(() => import('../../components/Page/Game'));
const Layout = lazy(() => import('../Layout'));
const GameLayout = lazy(() => import('../Layout/GameLayout'));
const SourceOfWealthPage = lazy(() => import('../../components/Page/SourceOfWealth'));
const TermsPolicyPage = lazy(() => import('../../components/Page/TermsPolicy'));

// const gamePaths = [
// eslint-disable-next-line max-len
//   '*/category/:slug/provider/:slug', '*/provider/:slug/category/:slug', '*/provider/:slug', '*/category/:slug',
// ];

const Router = () => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const userLoggedIn = useSelector(state => state.userState.userLoggedIn);
  const user = context.useUserData();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  if (userLoggedIn && user?.sessionKey) {
    if (anyConsentToAccept(user)) {
      if (location.pathname !== t('common:accept_conditions_link')) {
        history.push(t('common:accept_conditions_link'));
      }
    } else if (user?.kycStatus === 'REFER'
    && location.pathname !== t('common:verification_page_link')) {
      history.push(t('common:verification_page_link'));
    } else if (
      process.env.REACT_APP_SOURCE_OF_WEALTH_QUESTIONARE === 'true'
      && surveyConfig.NEED_TO_REDIRECT.includes(user?.sourceOfWealth)
      && user?.kycStatus !== 'REFER'
      && location.pathname !== t('common:source_of_wealth_link')
    ) {
      // If user is blocked, only the privacy-policy and source-of-wealth page are allowed
      if (!location.pathname.includes(t('common:privacy_policy_link'))) {
        history.push(t('common:source_of_wealth_link'));
      }
    }
  }

  return (
    <>
      <Suspense fallback={<PageLoader />}>
        <Switch>
          <ProtectedRoute exact path={[`*${t('common:accept_conditions_link')}`]} component={TermsPolicyPage} layout={Layout} />
          <ProtectedRoute exact path={[`*${t('common:source_of_wealth_link')}`]} component={SourceOfWealthPage} layout={Layout} />

          <Route exact path={['/password/reset', '/logout', '/signup']}>
            <Route component={ModalRenderer} />
          </Route>

          <AppRoute exact path="*/games/:slug" component={GamePage} layout={GameLayout} />
          <AppRoute exact path={`*/${t('common:blog')}/${t('common:categories')}/:slug`} component={BlogCategoryWrapper} layout={Layout} />

          {/* <AppRoute exact path={gamePaths} component={PageRenderer} layout={Layout} /> */}
          <AppRoute path="/" component={PageRenderer} layout={Layout} user={user} />
          <Route component={Page404} />
        </Switch>
      </Suspense>
      <ScrollToTop />
    </>
  );
};

export default Router;
