import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Flickity from 'react-flickity-component';
import AccountLoyaltyPointsWrapper from '../Account/AccountLoyaltyPointsWrapper';
import CarouselCellLevels from './CarouselCellLevels';

let flkty;

const LoyaltyPageHeaderLoggedIn = ({
  data,
  t,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(null);
  const flickityOptions = {
    lazyload: true,
    pageDots: false,
    prevNextButtons: false,
    fade: false,
    autoPlay: false,
    adaptiveHeight: false,
    imagesLoaded: true,
    pauseOnHover: false,
    groupCells: false,
  };

  useEffect(() => {
    if (flkty !== undefined) {
      /* istanbul ignore next */
      flkty.on('scroll', () => {
        setMaxIndex(flkty.slides.length);
        setCurrentIndex(flkty.selectedIndex);
      });
    }
  });

  function myCustomNext() {
    /* istanbul ignore next */
    flkty.next(false);
  }

  function myCustomPrev() {
    /* istanbul ignore next */
    flkty.previous(false);
  }

  return (
    <>
      <AccountLoyaltyPointsWrapper>
        {({
          accumulatedLoyaltyPoint,
          remainingPointsForNextLevel,
          currentVIPLevel,
          currentLoyaltyPoint,
        }) => (
          <>
            <div className="columns is-multiline is-mobile m-loyalty-top-area logged-in">
              <div className="column is-12-mobile is-12-tablet is-6-desktop has-text-white">
                <img className="m-loyalty-logo" width="526" src={`${process.env.PUBLIC_URL}/images/loyalty/superstar-logo.png`} alt="" />

                <div className="m-loyalty-v2-intro-text column is-11-tablet is-11-desktop is-9-widescreen">
                  <p className="is-size-6-tablet is-size-5-widescreen">
                    {/* eslint-disable-next-line react/no-danger */}
                    <span dangerouslySetInnerHTML={{ __html: data.sections[0]?.description }} />
                  </p>

                </div>
              </div>
              <div className="column is-12-mobile is-12-tablet is-6-desktop  m-loyalty-info-column">
                <div className="card m-user-dashboard-info m-loyalty-whammies-info has-background-white">
                  <div className="card-content content is-flex">
                    <div className="level is-mobile">
                      <div className="level-left">
                        <h3 id="text" className="has-text-primary is-marginless is-size-6-touch is-size-6-tablet is-size-5-widescreen">
                          {t('common:total_accumulated')}
                        </h3>
                      </div>
                      <div className="level-right">
                        <h3 className="has-text-primary is-marginless is-size-5-touch is-size-5-tablet is-size-4-desktop">{ accumulatedLoyaltyPoint }</h3>
                      </div>
                    </div>
                  </div>
                  <div className="card-content content is-flex">
                    <div className="level is-mobile">
                      <div className="level-left">
                        <h3 id="text" className="has-text-primary is-marginless is-size-6-touch is-size-6-tablet is-size-5-widescreen">
                          {t('common:your_current_level')}
                        </h3>
                      </div>
                      <div className="level-right">
                        <h3 className="has-text-primary is-marginless is-size-6-touch is-size-5-tablet is-size-5-desktop">{ currentVIPLevel }</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card m-user-dashboard-info m-loyalty-whammies-info has-background-dark-blue levels-background-color">
                  <div id="js-loyalty-status-carousel" className="m-loyalty-status-carousel">
                    <Flickity
                      /* eslint-disable-next-line no-return-assign */
                      className="js-carousel-slide"
                      options={flickityOptions}
                      flickityRef={c => flkty = c}
                    >

                      { data.level_details.map(item => (
                        CarouselCellLevels({
                          item,
                        })
                      ))}

                    </Flickity>
                    <div className="m-loyalty-shop-carousel-arrows">
                      <button
                        type="button"
                        id="js-carousel-loyalty-status-nav-left"
                        className="m-loyalty-shop-carousel-arrow"
                        style={{ opacity: `${currentIndex === 0 ? '0.25' : ''}` }}
                        disabled={currentIndex === 0}
                        onClick={myCustomPrev}
                      >
                        <img src={`${process.env.PUBLIC_URL}/images/loyalty/carousel-arrow.svg`} alt="" />
                      </button>
                      <button
                        type="button"
                        id="js-carousel-loyalty-status-nav-right"
                        className="m-loyalty-shop-carousel-arrow"
                        style={{ opacity: `${currentIndex === maxIndex - 1 ? '0.25' : ''}` }}
                        disabled={currentIndex === maxIndex - 1}
                        onClick={myCustomNext}
                      >
                        <img src={`${process.env.PUBLIC_URL}/images/loyalty/carousel-arrow.svg`} alt="" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="content m-margin-top-gap">
                  <p className="has-text-centered is-size-6 has-text-white">
                    {t('common:points_to_reach_1')}
                    {' '}
                    <b>{ remainingPointsForNextLevel }</b>
                    {' '}
                    {t('common:points_to_reach_2')}
                  </p>
                </div>
              </div>
            </div>
            <div className="columns is-centered m-loyalty-section m-loyalty-content-section">
              <div className="columns column is-6 is-centered">
                <div className="column is-11-tablet is-9-widescreen">
                  <div className="content has-text-left">
                    <h2 className="is-size-2 is-size-4-mobile has-text-white">{ data.sections[1]?.title }</h2>
                    <p className="m-margin-top-gap is-size-6-tablet is-size-5-widescreen has-text-left has-text-white">
                      <span dangerouslySetInnerHTML={{ __html: data.sections[1]?.description }} />
                    </p>
                  </div>
                </div>
              </div>
              <div className="column is-6 ">
                <div className="content has-text-left m-loyalty-info-column">
                  <div className="card m-user-dashboard-info m-loyalty-whammies-info has-background-white">
                    <div className="card-content content is-flex">
                      <div className="level is-mobile">
                        <div className="level-left">
                          <h3 id="text" className="has-text-primary is-marginless is-size-6-touch is-size-5">
                            {t('common:points_to_spend')}
                          </h3>
                        </div>
                        <div className="level-right">
                          <h3 className="has-text-primary is-marginless is-size-4-touch is-size-4">{ currentLoyaltyPoint }</h3>
                        </div>
                      </div>
                    </div>
                    <div className="card-content content is-flex">
                      <div className="level is-mobile">
                        <div className="level-left">
                          <p id="text" className="has-text-light-grey is-marginless is-size-6">
                            {t('common:not_affect_level')}
                          </p>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </AccountLoyaltyPointsWrapper>
    </>
  );
};

LoyaltyPageHeaderLoggedIn.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    banner: PropTypes.instanceOf(Object),
    description: PropTypes.string.isRequired,
    background_image: PropTypes.instanceOf(Object),
    foreground_image: PropTypes.instanceOf(Object),
    meta: PropTypes.instanceOf(Object),
    sections: PropTypes.instanceOf(Object),
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default LoyaltyPageHeaderLoggedIn;
