/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import Casino from '@dialinvest/react-casino';
import GamesWidget from './GamesWidget';
import PlayersWidget from './PlayersWidget';
import TimeWidget from './TimeWidget';
import { isUserLoggedIn, capitalize } from '../../Helpers';
import Loader from './Loader';
import TournamentCardTabs from './TournamentTabs';
import RankingsIcon from '../../../images/icons/rankings-icon.svg';
import GamesIcon from '../../../images/icons/games-icon.svg';
import { isMobile } from 'react-device-detect';

const TournamentWidget = ({
  active,
  id,
  name,
  description,
  price,
  prize,
  start,
  end,
  players,
  prices,
  games,
  menuID,
  omegaID,
  cookieValues,
  loginIsOpen,
  banner,
  provider,
  providerDetail,
}) => {
  const { t } = useTranslation();

  const [currentSlide, setTCurrentSlide] = React.useState(1);
  const [currentPlayers, setCurrentPlayers] = React.useState(players);
  const [optedPlayers, setOptedPlayers] = React.useState([]);
  const [currentOptedPlayer, setCurrentOptedPlayer] = React.useState({});
  const [gameList, setGameList] = React.useState([]);
  const [playerName, setPlayerName] = React.useState(active);
  const [loading, setLoading] = React.useState(true);
  const [ranking, setRanking] = React.useState(null);

  async function fetchTournament() {
    await getAllPlayers();
    await setCookieValue();
  }

  async function getAllPlayers() {
    const id = omegaID;

    const parameters = {
      id,
    };
    const result = await new Casino.models.Account().getTournamentRanking(
      parameters
    );
    getOptedPlayers(result.data.tournamentRankingList);
    setLoading(false);
  }

  async function setCookieValue() {
    if (document.cookie.indexOf(process.env.REACT_APP_API_COOKIE_NAME) >= 0) {
      cookieValues.username
        ? setPlayerName(capitalize(cookieValues.username.toLowerCase()))
        : setPlayerName('');
    }
  }

  function isOptIn() {
    return currentPlayers.filter((player) => player.username === playerName);
  }

  function next() {
    if (games.length > 1) {
      setTCurrentSlide(currentSlide + 1);
    }
  }

  function prev() {
    if (games.length > 1) {
      setTCurrentSlide(currentSlide - 1);
    }
  }

  function updateCurrentSlide(index) {
    if (currentSlide !== index) {
      setTCurrentSlide(index);
    }
  }

  /* istanbul ignore next */
  function optIn(id) {
    const parameters = {
      username: playerName,
      email: '',
      tournament: id,
    };

    if (playerName && id) {
      new Casino.models.Tournament().players(parameters).then(async () => {
        const url = `tournaments/?name=${name}`;
        const result = await new Casino.FetchContent(url).perform();
        setCurrentPlayers(result.items()[0].players);
      });
    }
  }

  function autoOptIn(tournamentId) {
    if (
      isUserLoggedIn() &&
      isOptIn().length === 0 &&
      (menuID === '1' || menuID === '2')
    ) {
      optIn(tournamentId);
    }
  }

  function hasEnded() {
    return new Date() > new Date(end);
  }

  function hasStarted() {
    return new Date() > new Date(start);
  }

  function getOptedPlayers(allPlayer) {
    let optedPlayer = {};
    // ************************************
    // set setOptedPlayers(optedPlayersList) if we bring back the opted in button!!
    // ************************************

    // eslint-disable-next-line no-unused-vars
    const optedPlayersList = allPlayer?.filter((player) =>
      players.some((p) => {
        if (player.userid.toLowerCase() === p.username.toLowerCase()) {
          if (player.userid === cookieValues.username) optedPlayer = player;
          return true;
        }
        return false;
      })
    );
    setOptedPlayers(allPlayer);
    setCurrentOptedPlayer(optedPlayer);
  }

  React.useEffect(() => {
    if (games.length) {
      const getGames = async () => {
        const result = await new Casino.FetchContent(
          `games/?id__in=${games.join(',')}&${
            isMobile ? 'supports_mobile=true' : 'supports_desktop=true'
          }`
        ).perform();
        setGameList(result?.data?.items);
      };

      getGames();
    }
  }, [games]);

  React.useEffect(() => {
    fetchTournament();
    setLoading(false);
    autoOptIn(id);
    setTCurrentSlide(0);
  }, [id, menuID, omegaID, players, loginIsOpen]);

  React.useEffect(() => {
    if (games.length > 1) {
      setTCurrentSlide(2);
    }
  }, [games]);
  return loading ? (
    <Loader />
  ) : (
    <section className="m-tournament-table is-open">
      <div className="m-tournament-table-header">
        <div id="reality_check" className="m-tournament-header-title">
          <h2 className="subtitle has-text-centered is-size-5 is-capitalized has-text-weight-bold mb-2">
            {name}
          </h2>
          <h1 className="title has-text-centered mr-1 is-size-5 is-capitalized">
            {price || prize}
          </h1>
        </div>
        <figure className="m-tournament-header-image">
          <img
            src={banner?.url ? banner?.url : '/images/tournament-default.webp'}
            alt="Tournament Banner"
          />
          {hasStarted() && !hasEnded() && (
            <div className="m-tournament-header-live">
              {t('common:live')}
              <span />
            </div>
          )}
        </figure>
        <div className="m-tournament-header-dates  has-text-centered">
          <TimeWidget
            start={start}
            end={end}
            menuID={menuID}
            id={id}
            active={active}
          />
          <div
            className="m-0 m-tournament-header-description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>

      <div className="m-tournament-table-body py-4">
        <div className="m-tournament-table-body-content">
          {!provider.name ? (
            <TournamentCardTabs
              tabs={[
                {
                  label: (
                    <>
                      <img width="25" src={GamesIcon} alt="tick-icon" />
                      <br />
                      <span>
                        {games.length > 1
                          ? t('common:tournament_games_in')
                          : t('common:tournament_game_in')}
                      </span>
                    </>
                  ),
                  id: 0,
                  hide: hasEnded(),
                  panel: (
                    <div className="column">
                      <section
                        className={`row m-game-section container is-fluid ${
                          games.length <= 1 ? 'one-game-only' : ''
                        }`}
                      >
                        <div className={`m-game-section-container ${!gameList?.length ? 'isLoading': ''}`}>
                          <div style={{ width: '100%' }}>
                            {games?.length >= 2 ? (
                              <Carousel
                                autoPlay
                                infiniteLoop
                                selectedItem={currentSlide}
                                onChange={updateCurrentSlide}
                                showArrows={false}
                                showIndicators={false}
                                showStatus={false}
                                interval={3000}
                                showThumbs={false}
                                centerMode
                              >
                                {gameList?.map((game) => (
                                  <GamesWidget game={game} key={game} />
                                ))}
                              </Carousel>
                            ) : games?.[0] ? (
                              <GamesWidget game={games[0]} />
                            ) : null}
                          </div>
                        </div>

                        <div className="m-game-navigation-left">
                          <img
                            src="/images/tournaments_left_arrow.png"
                            alt="left-navigation-arrow"
                            onClick={prev}
                          />
                        </div>
                        <div className="m-game-navigation-right">
                          <img
                            src="/images/tournaments_right_arrow.png"
                            alt="right-navigation-arrow"
                            onClick={next}
                          />
                        </div>
                      </section>

                      {isUserLoggedIn() &&
                        omegaID &&
                        currentOptedPlayer.userid && (
                          <>
                            <table className="table is-fullwidth my-4 current-player">
                              <tbody>
                                <tr className="has-text-right" />
                                <tr className="has-text-right is-active">
                                  <td className="m-user-name">
                                    {currentOptedPlayer.userid}
                                  </td>
                                  <td className="m-user-rank">
                                    {t('common:tournament_ranking')}
                                    &nbsp;
                                    {ranking}
                                  </td>
                                  <td className="m-user-score">
                                    {t('common:tournament_points')}
                                    &nbsp;
                                    {currentOptedPlayer.points}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                        )}
                    </div>
                  ),
                },
                {
                  label: (
                    <>
                      <img width="20" src={RankingsIcon} alt="tick-icon" />
                      <br />
                      <span>{t('common:tournament_rankings')}</span>
                    </>
                  ),
                  id: 1,
                  panel: (
                    <div className="column">
                      <div className="m-ranking-table-body">
                        <div className="m-ranking-table-wrapper">
                          <table className="table is-fullwidth">
                            <tbody>
                              <PlayersWidget
                                optedPlayers={optedPlayers}
                                currentPlayer={playerName}
                                currentPrices={prices}
                                onActiveRank={(activeRanking) =>
                                  setRanking(activeRanking)
                                }
                                getLogin={t('common:tournament_please_login')}
                              />
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ),
                },
              ]}
            />
          ) : (
            <>
              <div
                className={`m-tournament-detail-content content has-text-white ${
                  hasEnded() ? 'm-tournament-has-ended' : ''
                }`}
                dangerouslySetInnerHTML={{ __html: providerDetail }}
              />
              {hasStarted() && !hasEnded() && (
                <div className="buttons is-centered">
                  <a
                    href={`/games/?provider=${provider.slug}#gamesAnchor`}
                    className="button is-rounded is-success is-large"
                  >
                    {t('buttons:join_now')}
                  </a>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

TournamentWidget.propTypes = {
  cookieValues: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string,
  ]),
  active: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.string,
  prize: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
  players: PropTypes.instanceOf(Array),
  games: PropTypes.instanceOf(Array),
  prices: PropTypes.instanceOf(Array),
  allGames: PropTypes.instanceOf(Array),
  onSignupAction: PropTypes.func.isRequired,
  menuID: PropTypes.string.isRequired,
  omegaID: PropTypes.string,
  loginIsOpen: PropTypes.bool.isRequired,
  banner: PropTypes.instanceOf(Object).isRequired,
  provider: PropTypes.instanceOf(Array),
  providerDetail: PropTypes.string,
};

TournamentWidget.defaultProps = {
  cookieValues: [],
  active: '',
  id: '',
  name: '',
  description: '',
  price: '',
  prize: '',
  start: '',
  end: '',
  players: [],
  games: [],
  allGames: [],
  omegaID: '',
  prices: [],
  provider: [],
  providerDetail: '',
};

export default TournamentWidget;
