import React from 'react';
import PropTypes from 'prop-types';
import HeroBalance from './HeroBalance';
import HeroPromotions from './HeroPromotions';
import HeroBalanceBreakdown from './HeroBalanceBreakdown';

const LoggedInHeroSection = ({ featuredPromotion }) => (
  <div className="carousel m-carousel m-carousel-logged-in">
    <div className="container is-fluid">
      <section className="m-user-dashboard">
        <div className="container is-fluid">
          <div className="columns is-mobile is-multiline">
            <div className="column is-12-mobile is-6-tablet is-6-desktop is-4-widescreen">
              <div className="fx-drop-shadow" />
              <HeroBalanceBreakdown />
            </div>
            <div className="column is-12-mobile is-6-tablet is-6-desktop is-4-widescreen">
              <div className="fx-drop-shadow" />
              <HeroBalance />
            </div>
            {featuredPromotion
            && (
              <div className="column is-12-mobile is-12-tablet is-12-desktop is-4-widescreen">
                <div className="fx-drop-shadow" />
                <HeroPromotions featuredPromotion={featuredPromotion} />
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  </div>
);

LoggedInHeroSection.propTypes = {
  featuredPromotion: PropTypes.instanceOf(Object).isRequired,
};

export default LoggedInHeroSection;
