import getCountry from '../../../containers/utitilies/countries';
import SignupState from './SignupState';
import dialData from './country-dial-code.json';

export const setCurrencyValues = (currency) => {
  let selectedCurrency = currency.defaultValue;

  if (currency.items.length > 0) {
    selectedCurrency = currency.items[0].value;
  }

  currency.value = selectedCurrency;
  currency.valid = true;

  return currency;
};

export const adjustCurrenciesData = (currencies, translation) => (
  currencies.map(item => ({ name: translation(`currencies:${item}`), value: item }))
);

export const currenciesData = (prevState, currencies, translation) => {
  const addressDetailsConfig = { ...prevState.addressDetails };
  const currenciesList = adjustCurrenciesData(currencies, translation);

  addressDetailsConfig.fields.currency.items = currenciesList;
  if (addressDetailsConfig.fields.currency.countryIso) {
    addressDetailsConfig.fields.currency = setCurrencyValues(
      addressDetailsConfig.fields.currency,
    );
  }
  return { addressDetails: addressDetailsConfig };
};

export const localesData = (prevState, availableLocales, language, translation) => {
  const accountDetailsCopy = { ...prevState.accountDetails };
  let languageValue = '';
  const languages = availableLocales.map((locale) => {
    const languageIso = locale.iso;
    if (locale.locale === language) languageValue = languageIso;
    const languageName = translation(`languages:${locale.iso}`);
    return { value: languageIso, name: languageName };
  });

  accountDetailsCopy.fields.language.items = languages;
  accountDetailsCopy.fields.language.value = languageValue;
  return { accountDetails: accountDetailsCopy };
};

export const countriesData = (prevState, countries) => {
  const restrictedCountries = process.env.REACT_APP_REG_RESTRICTED_COUNTRIES.split(',');
  const addressDetailsCopy = { ...prevState.addressDetails };

  const localCountries = countries
    .filter(country => !restrictedCountries.includes(country))
    .map(country => ({ value: country, name: getCountry[country] || country }));

  addressDetailsCopy.fields.country.items = localCountries;
  return { addressDetails: addressDetailsCopy };
};

// eslint-disable-next-line max-len
export const getKeyByValue = (object, value) => Object.keys(object).find(key => object[key].field === value);

const clearFields = (fields) => {
  const clearValues = (items, key) => {
    items[key].value = '';
    items[key].valid = false;
    items[key].touched = false;
    items[key].onlineValid = false;
  };
  Object.entries(fields).map(([key]) => clearValues(fields, key));
  return fields;
};

export const clearFieldsValues = (prevState) => {
  let loadedExternalDataCopy = { ...prevState.loadedExternalData };
  let signupStateCopy = { ...prevState.signupState };
  const accountDetailsCopy = { ...prevState.accountDetails };
  const acceptFieldssCopy = { ...prevState.acceptFields };
  const personalDetailsCopy = { ...prevState.personalDetails };
  const addressDetailsCopy = { ...prevState.addressDetails };

  loadedExternalDataCopy = false;
  signupStateCopy = SignupState.first;
  accountDetailsCopy.fields = clearFields(accountDetailsCopy.fields);
  acceptFieldssCopy.fields = clearFields(acceptFieldssCopy.fields);
  personalDetailsCopy.fields = clearFields(personalDetailsCopy.fields);
  addressDetailsCopy.fields = clearFields(addressDetailsCopy.fields);

  return {
    loadedExternalData: loadedExternalDataCopy,
    signupState: signupStateCopy,
    accountDetails: accountDetailsCopy,
    acceptFields: acceptFieldssCopy,
    personalDetails: personalDetailsCopy,
    addressDetails: addressDetailsCopy,
  };
};

export const setDefaults = (prevState, response) => {
  const accountDetailsCopy = { ...prevState.accountDetails };
  const addressDetailsCopy = { ...prevState.addressDetails };
  const acceptFieldsCopy = { ...prevState.acceptFields };

  acceptFieldsCopy.fields.acceptAgeTerms.value = true;
  acceptFieldsCopy.fields.acceptPrivacy.value = true;
  acceptFieldsCopy.fields.acceptPromotions.value = true;
  acceptFieldsCopy.fields.contactPreference.value = true;

  addressDetailsCopy.fields.currency.countryIso = response.currency.isoCode;

  if (addressDetailsCopy.fields.currency.items !== []
    && addressDetailsCopy.fields.currency.items !== undefined) {
    addressDetailsCopy.fields.currency = setCurrencyValues(
      addressDetailsCopy.fields.currency,
    );
  }
  addressDetailsCopy.fields.country.value = response.countryInfo.iso2Code;

  function getDialCode(countryCode) {
    return dialData.filter(countryInfo => countryInfo.code === countryCode);
  }

  const countryInfo = getDialCode(response.countryInfo.iso2Code);
  // eslint-disable-next-line no-unused-expressions
  (countryInfo[0] !== undefined) ? addressDetailsCopy.fields.mobile.value = countryInfo[0].dial_code : '';
  addressDetailsCopy.fields.country.valid = true;
  accountDetailsCopy.fields.language.valid = true;

  return {
    accountDetails: accountDetailsCopy,
    addressDetails: addressDetailsCopy,
    acceptFields: acceptFieldsCopy,
  };
};
