import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { BalanceField } from './HeroBalance';
import AccountBalanceWrapper from '../Account/AccountBalanceWrapper';

export const HeroBalanceBreakDownBody = ({ balance, withdrawableBalance, bonusBalance }) => {
  const { t } = useTranslation();

  return (
    <div className="card m-user-dashboard-card content">
      <div className="card-title">
        <h4 className="has-text-primary is-size-6-mobile has-text-centered-mobile">
          {t('account:your_balance_breakdown')}
        </h4>
      </div>
      <div className="card-content has-text-primary content">
        <BalanceField text={t('account:your_balance')} balance={balance} />
        <BalanceField text={t('account:withdrawable_balance')} balance={withdrawableBalance} />
        <BalanceField text={t('account:bonus_balance')} balance={bonusBalance} />
      </div>
    </div>
  );
};

HeroBalanceBreakDownBody.propTypes = {
  balance: PropTypes.string,
  withdrawableBalance: PropTypes.string,
  bonusBalance: PropTypes.string,
};

HeroBalanceBreakDownBody.defaultProps = {
  balance: '00.00',
  withdrawableBalance: '00.00',
  bonusBalance: '00.00',
};

const HeroBalanceBreakdown = () => (
  <AccountBalanceWrapper>
    {props => (
      <HeroBalanceBreakDownBody {...props} />
    )}
  </AccountBalanceWrapper>
);

export default HeroBalanceBreakdown;
