import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import Casino, { components } from '@dialinvest/react-casino';
import { Cookies } from 'react-cookie';
import CashierInstance, { resetCashierInstance } from './CashierInstance';
import {
  clipHTMLBody,
  isUserLoggedIn,
  isMobile,
  getBonuses,
  filterBonusByDescription,
  getQueryParam,
  checkSessionCookie,
  unClipHTMLBody,
} from '../../Helpers';
import { toggleModalIn } from '../../../redux/actions/signInModalActions';
import currencyMark, { currencyAmount, currencyPredefinedAmounts } from '../../../containers/utitilies/currencies';
import { balanceRefresh } from '../../../redux/actions/balanceActions';
import BonusSlider from '../../BonusSlider';
import SimpleBonusSlider from './SimpleBonusSlider';
import { FooterMessage } from '../FooterMessage';

export const COINSPAID = 'CP_COINSPAID';

class Cashier extends PureComponent {
  BONUS_PATTERN = 'welcombo';
  BONUS_PATTERN_SECOND = '2nd Deposit Bonus';
  BONUS_PATTERN_THIRD = '3rd Deposit Bonus';

  constructor(props) {
    super(props);

    this.state = {
      totalBalance: 0,
      withdrawableBalance: 0,
      bonusBalance: 0,
      currency: 'EUR',
      currencyAmount: '30',
      currencyPredefinedAmounts: '30, 100, 500',
      loading: true,
      checkingBonuses: true,
      merchantId: '100262999',
      availableBonuses: [],
      availableFilteredBonuses: [],
      providerType: '',
      bonusSliderClosed: true,
      disableSlider: false,
      accountDelete: true,
      reloadedOnError: false,
      depositNr: null,
      isBonusSelector: !!getQueryParam('bonus', window.location.search),
      showCrypto: false,
    };
    this.method = 'deposit';
    this.providerType = null;
    this.totalBalance = 0;
    this.withdrawableBalance = 0;
    this.bonusBalance = 0;
    this.instance = null;
    this.cookieValues = new Cookies().get(process.env.REACT_APP_API_COOKIE_NAME);
    this.selectedBonus = '';
    this.bonusSearchParam = null;
    this.bonusFieldHideList = [];
    this.currencyAmount = '30';
    this.currencyPredefinedAmounts = '30, 100, 500';
    this.limitsData = {};
    this.limitsErrorMsg = false;
  }

  componentDidMount = () => {
    const {
      isOpen,
      onToggleModalIn,
      onCashierModalIn,
      providerType,
      location,
    } = this.props;

    this.cookieValues = new Cookies().get(process.env.REACT_APP_API_COOKIE_NAME);
    this.bonusSearchParam = getQueryParam('bonus', location.search);

    document.addEventListener('keydown', this.escFunction, false);
    if (isOpen === true && isUserLoggedIn()) {
      this.initState();
    }

    if (isOpen === true && !isUserLoggedIn()) {
      onCashierModalIn();
      clipHTMLBody();
      onToggleModalIn(null, null, 'cashier', providerType);
      clipHTMLBody();
      window.scrollTo(0);
    }

    if (!isOpen && this.bonusSearchParam) {
      onCashierModalIn();
    }
    this.fetchSettings();
  };

  componentDidUpdate = (prevProps) => {
    const { props } = this;

    this.cookieValues = new Cookies().get(process.env.REACT_APP_API_COOKIE_NAME);

    if (
      prevProps.isOpen !== props.isOpen
      && props.isOpen === true && !isUserLoggedIn()) {
      props.onCashierModalIn();
      clipHTMLBody();
      props.onToggleModalIn(null, null, 'cashier', props.providerType);
      clipHTMLBody();
    }
    if (
      prevProps.isOpen !== props.isOpen
      && props.isOpen === true && isUserLoggedIn()) {
      (prevProps.cashierType !== props.cashierType) ? this.method = props.cashierType : this.method = 'deposit';
      // eslint-disable-next-line max-len
      (prevProps.providerType !== props.providerType) ? this.providerType = props.providerType : this.providerType = null;
      this.resetInstance();
      this.initState();
      ReactGA.modalview(this.method);
    }

    this.showBonusField();
    this.enableAccountDelete();
  };

  fetchSettings = async () => {
    const result = await new Casino.FetchContent('site-settings/').perform();
    if (result?.data?.items?.length > 0) {
      this.setState({ merchantId: result.data.items[0].merchant_id });
    }
  };

  initState = async (data = null) => {
    const account = new Casino.models.Account();
    const { sessionKey } = this.cookieValues;
    const payment = new Casino.models.Payment();

    const parameters = {
      sessionKey,
      paymentMethodCode: 'PAYMENTIQ',
    };

    account.getBalanceSimple(sessionKey).then((response) => {
      this.totalBalance = response.data.totalBalance !== undefined ? response.data.totalBalance : 0;
      // eslint-disable-next-line max-len
      this.withdrawableBalance = response.data.withdrawableBalance !== undefined ? response.data.withdrawableBalance : 0;
      this.bonusBalance = response.data.bonusBalance !== undefined ? response.data.bonusBalance : 0;
      this.currencyAmount = currencyAmount[response.data.currency] !== undefined ? currencyAmount[response.data.currency] : this.currencyAmount;
      this.currencyPredefinedAmounts = currencyPredefinedAmounts[response.data.currency] !== undefined ? currencyPredefinedAmounts[response.data.currency] : this.currencyPredefinedAmounts;
      this.setState({
        totalBalance: this.totalBalance,
        currency: response.data.currency,
        currencyAmount: this.currencyAmount,
        currencyPredefinedAmounts: this.currencyPredefinedAmounts,
        withdrawableBalance: this.withdrawableBalance,
        bonusBalance: this.bonusBalance,
      });

      if (data === null) {
        this.changeMethod(this.method);
      }
    }).catch(err => console.error(err));

    if (this.method === 'deposit') {
      try {
        const response = await account.getDepositLimits(parameters);
        this.limitsData = response?.data;
        // eslint-disable-next-line max-len
        this.limitsErrorMsg = (parseInt(response?.data?.dailyLimit, 10) < 10 || parseInt(response?.data?.weeklyLimit, 10) < 10 || parseInt(response?.data?.monthlyLimit, 10) < 10);
      } catch (error) {
        console.log(error);
      }
    }

    if (!this.limitsErrorMsg) {
      try {
        const methodsResponse = this.method === 'deposit'
          ? (await payment.getDepositMethods(sessionKey))?.data?.depositMethods
          : (await payment.getWithdrawalMethods(sessionKey))?.data?.withdrawalMethods;

        const hasPermissionToCrypto = methodsResponse?.find(
          method => method.code === COINSPAID,
        );

        this.setState(prevState => ({
          ...prevState,
          showCrypto: !!hasPermissionToCrypto,
        }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  checkBonusPlanAvailable = async () => {
    const { sessionKey } = this.cookieValues;

    getBonuses(sessionKey)
      .then(async (bonus) => {
        const nextBonus = bonus.current;
        this.setState(prevState => ({
          ...prevState,
          checkingBonuses: false,
          availableBonuses: bonus?.all || [],
          availableFilteredBonuses: filterBonusByDescription(bonus.all),
          currentBonus: nextBonus,
          depositNr: bonus.depositNr,
        }));
      });

    return true;
  };

  // eslint-disable-next-line no-unused-vars
  onSuccess = async (data) => {
    if (typeof data.data !== 'undefined') {
      const { props } = this;
      props.refreshBalance();
      this.initState('update');
    }
  };

  // eslint-disable-next-line no-unused-vars
  doneLoading = (data) => {
    if (data.data === 'DONE_LOADING') {
      this.setState({ loading: false });
      window.scrollTo(0, 0);
    }
  };

  showBonusField = () => {
    const {
      availableBonuses, providerType, disableSlider, bonusSliderClosed,
    } = this.state;
    if ((!this.instance || availableBonuses.length === 0 || providerType === '') && (!bonusSliderClosed)) return;

    let showBonusCode = false;

    const shouldHideField = this.bonusFieldHideList.find(
      element => (element === providerType),
    ) !== undefined;

    // eslint-disable-next-line
    if (bonusSliderClosed) {
      showBonusCode = (!shouldHideField && this.selectedBonus !== '');
    } else if (disableSlider !== shouldHideField) {
      this.setState({ disableSlider: shouldHideField });
    }

    this.configCashierInstance({ showBonusCode });
  };

  enableAccountDelete = () => {
    const {
      providerType,
    } = this.state;

    if ((!this.instance || providerType !== 'MUCHBETTER')) {
      this.configCashierInstance({ accountDelete: true });
    } else {
      this.configCashierInstance({ accountDelete: false });
    }
  };

  paymentMethodSelect = (data) => {
    this.setState({ providerType: data.data.providerType });

    if (this.bonusFieldHideList.includes(data.data.providerType)) {
      this.onBonusChange('');
    } else {
      this.onBonusChange(this.selectedBonus);
    }
  };

  resetBonuses = () => {
    this.setState({
      availableBonuses: [],
      checkingBonuses: true,
      loading: true,
      isBonusSelector: true,
    });
    this.selectedBonus = '';
    this.bonusSearchParam = null;
  };

  toggleModal = (e = null) => {
    const modal = document.getElementById('cashier-deposit');
    if (modal) modal.scrollTo(0, 0);
    if (e) e.preventDefault();
    const { props } = this;
    props.onCashierModalIn();

    if (modal) {
      unClipHTMLBody();
    } else {
      clipHTMLBody();
    }

    props.refreshBalance();
    this.resetInstance();
    this.resetBonuses();
    this.setState(prevState => ({
      ...prevState,
      loading: true,
    }));
  };

  openBonusPreSelector = () => {
    this.setState(prevState => (
      {
        ...prevState,
        isBonusSelector: true,
      }
    ));
  };

  adjustLocale = locale => (locale === 'de' ? 'de_DE' : locale);

  changeMethod = (method) => {
    this.resetInstance();
    this.setState(prevState => ({
      ...prevState,
      loading: true,
      checkingBonuses: true,
      availableBonuses: [],
      providerType: '',
      disableSlider: false,
      isBonusSelector: method === 'deposit' && !!getQueryParam('bonus', window.location.search),
    }));
    this.setInstance(method);
    this.method = method;
    if (method === 'deposit') this.checkBonusPlanAvailable();
  };

  onLoadError = () => {
    const { reloadedOnError } = this.state;

    if (reloadedOnError) return;

    this.setState(prevState => ({
      ...prevState,
      reloadedOnError: true,
    }));

    this.initState();
  };

  scrollToTopIframe = () => document.getElementById(isMobile().any() ? 'cashier-deposit' : 'cashier-deposit-content')?.scrollTo(0, 0);

  navigate = () => {
    this.onBonusSliderClose();
    this.scrollToTopIframe();
  }

  setInstance = (method) => {
    const { state, props } = this;

    this.instance = CashierInstance(
      this.cookieValues.sessionKey,
      this.cookieValues.partyId,
      method,
      /* istanbul ignore next */
      data => this.onSuccess(data),
      /* istanbul ignore next */
      data => this.doneLoading(data),
      /* istanbul ignore next */
      data => this.paymentMethodSelect(data),
      /* istanbul ignore next */
      data => this.onLoadError(data),
      data => this.navigate(data),
      this.providerType || null,
      this.adjustLocale(props.i18n.language.split('-')[0]),
      state.merchantId,
      state.currencyAmount,
      state.currencyPredefinedAmounts,
    );
  };

  closeBonusPreSelector = () => {
    this.setState(prevState => (
      {
        ...prevState,
        isBonusSelector: false,
      }
    ));
  };

  resetInstance = () => {
    if (this.instance == null) return;
    resetCashierInstance();
    this.instance = null;
    this.selectedBonus = '';
  };

  escFunction = (event) => {
    const { props } = this;
    if (event.keyCode === 27 && props.isOpen) {
      // Do whatever when esc is pressed
      this.toggleModal(event);
    }
  };

  onBonusSliderClose = () => {
    this.onBonusChange('');
    this.setState({ availableBonuses: [], bonusSliderClosed: true });
  };

  onBonusChange = (bonus) => {
    try {
      this.instance.set(
        {
          attributes: {
            bonusCode: bonus,
          },
        },
      );
      if (bonus !== '') this.selectedBonus = bonus;
      this.closeBonusPreSelector();
      // eslint-disable-next-line no-empty
    } catch (error) { }
  };

  configCashierInstance = (config) => {
    try {
      this.instance.set(
        {
          config,
        },
      );
      // eslint-disable-next-line no-empty
    } catch (error) { }
  };

  onBonusesChecked = () => {
    this.setState({ checkingBonuses: false });
  };

  onCryptoSuccess = () => {
    this.initState('update');
  };

  onDepositWoBonus = () => {
    this.configCashierInstance({ showBonusCode: false });
    this.onBonusChange('');
    this.selectedBonus = '';
    this.closeBonusPreSelector();
  };

  render() {
    const { isOpen, t, i18n } = this.props;
    const {
      totalBalance,
      withdrawableBalance,
      bonusBalance,
      currency,
      loading,
      availableBonuses,
      availableFilteredBonuses,
      checkingBonuses,
      disableSlider,
      depositNr,
      isBonusSelector,
      showCrypto,
    } = this.state;

    const isValidSession = checkSessionCookie();
    if (!isValidSession) return <></>;

    return (
      <div
        id="modal-bambora-cashier"
        className={`modal m-modal-base modal-fx-superScaled m-modal-fixed m-modal-full-height ${isOpen ? 'is-active' : 'is-inactive'}
        ${availableBonuses?.length > 0 && this.method === 'deposit' ? 'bonus-is-active' : ''} `}
      >
        <div className="modal-background" onClick={e => this.toggleModal(e)} onKeyDown={e => this.toggleModal(e)} />
        <div className="modal-card modal-content">
          <header className="modal-card-head is-flex is-align-items-center py-2-5 px-5">
            <div className="m-cashier-controls is-flex is-justify-content-space-between is-align-items-center is-full-width has-text-centered m-tabs">
              <div className="is-6-mobile is-2-tablet has-text-left has-text-white">
                <p className="has-text-white is-size-2 is-hidden-mobile"><b>{this.method !== 'deposit' ? 'Withdraw' : 'Deposit'}</b></p>
              </div>
              <div className="m-cashier-balance-container is-full-width">
                <div className="card m-user-dashboard-info m-cashier-balance has-background-white">
                  <div className="card-content ">
                    <div className="level is-mobile">
                      <div className="level-left">
                        <h3 className="has-text-primary is-marginless is-size-6-touch is-size-5">
                          <i className="icon-deposit" />
                        </h3>
                      </div>
                      <div className="level-item">
                        <div className="level">
                          <div className="level-left">
                            <h3 className="is-marginless is-size-5-mobile is-size-6">
                              <strong
                                className="has-text-primary"
                              >
                                <small>
                                  {t('common:balance')}
                                  :
                                </small>
                              </strong>
                            </h3>
                          </div>
                          <div className="level-right">
                            <h3 className="is-marginless is-size-6">
                              <strong className="has-text-primary">
                                <small>
                                  {`${currencyMark[currency]}${totalBalance}`}
                                </small>
                              </strong>
                            </h3>
                          </div>
                        </div>
                        <div className="level">
                          <div className="level-left">
                            <h3 className="is-marginless is-size-5-mobile is-size-6">
                              <strong className="has-text-primary">
                                <small>
                                  {t('common:bonus')}
                                  :
                                </small>
                              </strong>
                            </h3>
                          </div>
                          <div className="level-right">
                            <h3 className="is-marginless is-size-6">
                              <strong className="has-text-primary">
                                <small>
                                  {`${currencyMark[currency]}${bonusBalance}`}
                                </small>
                              </strong>
                            </h3>
                          </div>
                        </div>
                        {this.method !== 'deposit' && (
                          <div className="level">
                            <div className="level-left">
                              <h3 className="is-marginless is-size-5-mobile is-size-6">
                                <strong className="has-text-primary">
                                  <small>
                                    {t('common:withdrawable')}
                                    :
                                  </small>
                                </strong>
                              </h3>
                            </div>
                            <div className="level-right">
                              <h3 className="is-marginless is-size-6">
                                <strong className="has-text-primary">
                                  <small>
                                    {`${currencyMark[currency]}${withdrawableBalance}`}
                                  </small>
                                </strong>
                              </h3>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {this.method === 'deposit' && !this.limitsErrorMsg
                  ? (
                    <button
                      type="button"
                      className={`button is-outline m-bonus-preselector-button ${isBonusSelector ? 'is-disabled' : ''}`}
                      onClick={this.openBonusPreSelector}
                    >
                      {t('buttons:claim_bonus')}
                    </button>
                  )
                  : ''}
              </div>
            </div>
            <button
              id="delete"
              type="button"
              className="delete button-modal-close"
              aria-label="close"
              onClick={e => this.toggleModal(e)}
            />
            <button
              type="button"
              className="m-modal-close-button button-modal-close"
              aria-label="close"
              onClick={e => this.toggleModal(e)}
            >
              <i className="fas fa-times-circle" />
            </button>
          </header>
          {this.limitsErrorMsg && this.method === 'deposit'
            ? (
              <div className="deposit-limit-error-box is-flex is-justify-content-center is-align-items-center is-flex-direction-column">
                <h1 className="has-text-centered is-size-4 has-text-weight-bold has-text-danger">{t('errors:deposit_limit_reached')}</h1>
                <h2 className="is-size-5 has-text-weight-bold">
                  <span dangerouslySetInnerHTML={{ __html: t('errors:deposit_limit_settings') }} />
                </h2>
                <div>
                  <p className="is-size-6 has-text-weight-bold">{t('errors:deposit_limit_reached_supplementary')}</p>
                  <p className="is-size-6 has-text-weight-bold">{t('errors:deposit_limit_reached_contact')}</p>
                </div>
              </div>
            )
            : (
              <>
                <FooterMessage />
                {(availableBonuses.length > 0 && this.method === 'deposit' && !loading && process.env.REACT_APP_PRE_BONUS_SELECTOR === 'false' && depositNr === 0) && (
                <BonusSlider
                  onBonusChange={this.onBonusChange}
                  onClose={this.onBonusSliderClose}
                  availableBonuses={availableBonuses}
                  disableSlider={disableSlider}
                  t={t}
                />
                )}
                {(availableBonuses.length > 0 && this.method === 'deposit' && !loading && process.env.REACT_APP_PRE_BONUS_SELECTOR === 'false' && (depositNr === 1 || depositNr === 2)) && (
                <SimpleBonusSlider
                  onClose={this.onBonusSliderClose}
                  availableBonuses={availableBonuses}
                  disableSlider={disableSlider}
                  t={t}
                  i18n={i18n}
                />
                )}
                <section className="modal-card-body">
                  {(this.method === 'deposit' && !loading && isBonusSelector && process.env.REACT_APP_PRE_BONUS_SELECTOR === 'true') && (
                  <components.PreBonusSelector
                    bonuses={availableBonuses}
                    filteredBonuses={availableFilteredBonuses}
                    onBonusSelect={this.onBonusChange}
                    onCancel={this.toggleModal}
                    onDepositWoBonus={this.onDepositWoBonus}
                    isLoading={checkingBonuses}
                    preSelected={this.bonusSearchParam}
                  />
                  )}
                  {(showCrypto && process.env.REACT_APP_PAYMENTIQ_CRYPTO === 'false' && (this.cookieValues && !loading && (!isBonusSelector || process.env.REACT_APP_PRE_BONUS_SELECTOR === 'false')))
              && (
                <>
                  <components.CryptoPaymentProvider
                    sessionKey={this.cookieValues.sessionKey}
                    type={this.method}
                    onSuccess={this.onCryptoSuccess}
                    playerCurrency={currency}
                    defaultBonusCode={this.selectedBonus}
                  />
                </>
              )}
                  {loading
              && (
                <div className="content">
                  <span className="middle-loader">
                    <img width="150px" src={`${process.env.PUBLIC_URL}/images/omnislots_preloader_loop.gif`} alt="Omnislots" />
                  </span>
                </div>
              )}
                  <div
                    id="cashier-deposit-content"
                    className={`content ${loading || isBonusSelector ? 'is-hidden' : ''}`}
                  >
                    <div id="cashier-panel-01" className="m-tab-panel is-active">
                      <div id="cashier-deposit" />
                    </div>
                    <div id="cashier-panel-02" className="m-tab-panel">
                      <div id="cashier-withdraw" />
                    </div>
                  </div>
                </section>
                <footer className="modal-card-foot is-hidden-mobile">
                  <button
                    id="close"
                    type="button"
                    className="button is-centered button-modal-close has-background-light is-medium is-small-mobile"
                    onClick={e => this.toggleModal(e)}
                  >
                    <i className="icon-close" />
              &nbsp;&nbsp;
                    {t('buttons:close')}
              &nbsp;&nbsp;
                  </button>
                </footer>
                <FooterMessage />
              </>
            )}
        </div>
      </div>
    );
  }
}

Cashier.propTypes = {
  onCashierModalIn: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  cashierType: PropTypes.string.isRequired,
  providerType: PropTypes.string,
  onToggleModalIn: PropTypes.func.isRequired,
  refreshBalance: PropTypes.func.isRequired,
  i18n: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
};

Cashier.defaultProps = {
  providerType: '',
};

export const mapStateToProps = state => ({
  isOpen: state.cashierModal?.isOpen,
  cashierType: state.cashierModal?.cashierType,
  providerType: state.cashierModal?.providerType,
});

export const mapDispatchToProps = dispatch => ({
  onCashierModalIn: () => dispatch({ type: 'TOGGLE_MODAL_CASHIER', cashierType: 'deposit' }),
  refreshBalance: () => dispatch(balanceRefresh()),
  // eslint-disable-next-line max-len
  onToggleModalIn: (errorMessage, modalType, sourcePage, providerType) => dispatch(toggleModalIn(errorMessage, modalType, sourcePage, providerType)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(Cashier);
