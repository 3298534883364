import React from 'react';
import PropTypes from 'prop-types';
import { isUserLoggedIn } from '../../Helpers';

const LoggedOut = ({
  data,
  buttonLabel,
  onClick,
  t,
}) => (
  <>
    <div className="container section m-vip-section is-fluid has-text-centered content">
      {/* eslint-disable-next-line react/no-danger */}
      <p dangerouslySetInnerHTML={{ __html: data.benefits_description }} />
      <h2 className="has-text-primary">{ data.benefits_title }</h2>
      <br />
      <div className="columns is-centered">
        <div className="column is-12 is-11-widescreen">
          <div className="columns is-mobile is-multiline is-centered m-vip-icons">
            { data.benefits.map(item => (
              <div className="column is-12-mobile is-4-tablet is-2-desktop has-text-primary">
                <div className="m-vip-benefit">
                  <img className="icon-bonus" src={item.icon.url} alt={item.title} />
                  <p>{item.title}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    <div className="has-background-primary">
      <div
        className="container section m-vip-section box has-background-transparent is-fluid has-text-centered has-text-white content"
      >
        <div className="columns is-centered">
          <div className="column is-7 has-text-white">
            <h2 className="has-text-white">{ data.title }</h2>
            <p dangerouslySetInnerHTML={{ __html: data.description }} />
            {/* OMG-2120 - Apply Vip button is removed temporary. */}
            {!isUserLoggedIn() ? (
              <a
                href="/#"
                data-modal-target="modal-signup"
                id="modal-signup-button"
                className="button is-white is-uppercase"
                onClick={e => onClick(e)}
              >
                { buttonLabel }
              </a>
            )
              : (
                <>
                  {t('common:to_join_vip_please_email')}
                  &nbsp;
                  <a className="has-text-white has-text-underline" href={`mailto:${process.env.REACT_APP_VIP_EMAIL}`}>{process.env.REACT_APP_VIP_EMAIL}</a>
                </>
              )}
          </div>
        </div>

      </div>
    </div>
  </>
);

LoggedOut.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    sub_title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    banner_title: PropTypes.string.isRequired,
    banner_description: PropTypes.string.isRequired,
    benefits_title: PropTypes.string.isRequired,
    benefits_description: PropTypes.string.isRequired,
    banner: PropTypes.instanceOf(Object),
    body: PropTypes.string.isRequired,
  }).isRequired,
  buttonLabel: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default LoggedOut;
