/* eslint-disable import/prefer-default-export */

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import usePlayer from '../usePlayer';
import { gamesApi } from '../../lib/api/cms/gamesService/Games';
import { setActiveGamesCategory } from '../../redux/actions/gamesActions/gamesAction';
import { usePersonalizedSite } from './useGetPersonalizedSite';
import { GamesCache, GamesKeys } from './useGetTopGames';

export function useRecommendedGames() {
  const { player } = usePlayer();
  const [games, setGames] = React.useState();
  const [state, setState] = React.useState('loading');
  const dispatch = useDispatch();

  const { data: personalizedGames, state: personalizedState } =
    usePersonalizedSite();

  const partyId = player?.partyId;

  useEffect(() => {
    if (games?.length || state === 'error' || partyId) return;

    const getData = async () => {
      setState('loading');
      let recommendedGames = GamesCache.get(GamesKeys.recommendedGAmes);

      try {
        dispatch(setActiveGamesCategory({ recommendedGames: true }));

        if (!recommendedGames) {
          const response = await gamesApi.getRecommendedGames({
            recommendationType: 'because_you_played',
          });

          if (!response?.data?.items?.length) {
            setState('error');
            return;
          }
          recommendedGames = (() => {
            if (response?.data?.items?.length >= 12) {
              return response?.data?.items?.slice(0, 12);
            }
            if (response?.data?.items?.length >= 6) {
              return response?.data?.items?.slice(0, 6);
            }
            if (response?.data?.items?.length < 6) {
              return [];
            }
          })();

          if (!recommendedGames?.length) {
            setState('error');
            return;
          }
          GamesCache.set(GamesKeys.recommendedGAmes, recommendedGames);
        }
        setGames(recommendedGames);
        setState('idle');
      } catch (error) {
        setState('error');
      }
    };
    getData();
  }, [partyId, games, state, dispatch]);

  useEffect(() => {
    if (state === 'error') {
      dispatch(setActiveGamesCategory({ recommendedGames: false }));
    }
  }, [state, dispatch]);

  useEffect(() => {
    if (partyId) {
      setState(personalizedState);
      dispatch(setActiveGamesCategory({ recommendedGames: true }));
      if (personalizedGames?.recommended_for_you?.length) {
        const modifedGames = (() => {
          if (personalizedGames?.recommended_for_you?.length >= 12) {
            return personalizedGames?.recommended_for_you?.slice(0, 12);
          }
          if (personalizedGames?.recommended_for_you?.length >= 6) {
            return personalizedGames?.recommended_for_you?.slice(0, 6);
          }
          if (personalizedGames?.recommended_for_you?.length < 6) {
            return [];
          }
        })();
        setGames(modifedGames);
      }
    }
  }, [partyId, setState, personalizedState, personalizedGames, dispatch]);

  return {
    games,
    state,
  };
}
