import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import moment from 'moment';
import Casino from '@dialinvest/react-casino';
import Loader from 'react-loader-spinner';
import { changeUserNameWithStarWinners } from '../../Helpers';
import { Link } from 'react-router-dom';
import useGetPrize from '../../Page/TournamentsPage/useGetPrize.jsx';

const CurrentTournamentCard = () => {
  const [tournaments, setTournaments] = useState([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [timer, updateTimer] = useState({});
  const { getPrize } = useGetPrize();

  /* istanbul ignore next */
  const countdownTimer = (endDate) => {
    const countDownDate = new Date(endDate).getTime();
    const now = new Date().getTime();
    const distance = countDownDate - now;

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  React.useEffect(() => {
    setLoading(true);
    let active = true
    let interval;
    const getAllPlayerFromTournamentRanking = async (omegaID, players) => {
      const response = await new Casino.models.Account().getTournamentRanking({
        id: omegaID,
      });
      //show all players from omega, users not need to opten in
/*       const optedPlayersList = response?.data?.tournamentRankingList?.filter(
        (player) =>
          players.some(
            (p) => player.userid.toLowerCase() === p.username.toLowerCase()
          )
      ); */

      const sortedPlayers = response?.data?.tournamentRankingList
        .sort((a, b) => parseFloat(b.points) - parseFloat(a.points))
        .slice(0, 10);

      return sortedPlayers;
    };

    const getTournamentsData = async () => {
      try {
        const today = moment().utc().format('YYYY-MM-DD HH:mm:ss');
        const url = `tournaments/?starts_at_lte=${today}&ends_at_gte=${today}`;
        const response = await new Casino.FetchContent(url).perform();
        const result = response?.data?.items[0];

        if (result) {
          const rankingData = await getAllPlayerFromTournamentRanking(
            result.omega_id,
            result.players
          );

          setTournaments((prevState) => ({
            ...prevState,
            name: result.name,
            prices: result.prices,
            endDate: result.ends_at,
            ranking: result.omega_id,
            rankingData,
          }));

          /* istanbul ignore next */
          interval = setInterval(() => {
            const {days, hours, minutes, seconds} = countdownTimer(tournaments.endDate)
            if (tournaments?.endDate) {
              updateTimer({
                days,
                hours,
                minutes,
                seconds,
              });
            }
          }, 1000);
        }
      } catch (error) {
        console.log(error);
      } finally {
        if(active) {
          setLoading(false);
        }
      }
    };
    getTournamentsData();

    return () => {
      clearInterval(interval)
      active = false
    }
  }, [tournaments.endDate, updateTimer]);

  return (
    <section className="column m-winnerslist-card m-winnerslist-card__current has-text-white has-text-centered">
      <div className="m-winnerslist-card__container">
        <div className="m-winnerslist-card__header">
          <div className="m-winnerslist-card__maintitle m-winnerslist-card__maintitle_current">
            <h2 className="m-winnerslist-card__title is-size-6 has-text-weight-bold">
              <img
                src="/images/winners-widget/title-current-winners.png"
                alt={t('common:current_winners_list_title')}
                loading="lazy"
              />
              <span className="is-sr-only">
                {t('common:current_winners_list_title')}
              </span>
            </h2>
          </div>
        </div>
        {loading ? (
          <div
            style={{
              height: '80%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Loader color="#552c88" type="ThreeDots" />
          </div>
        ) : (
          <>
            <div className="m-winnerslist-card__sub-title-container">
              <Link to="/tournaments" className="button is-tournament is-size-6">
                {tournaments?.name
                  ? tournaments?.name
                  : t('common:no_tournaments')}
              </Link>
            </div>
            <div className="m-winnerslist-card__rankings">
              {!tournaments?.rankingData
                ? null
                : tournaments?.rankingData?.map((player, index) => (
                    <div
                      key={`${index}_${tournaments?.rankingData?.userid}`}
                      className="m-winnerslist-card__rankings-player"
                    >
                      <div className="item-container">
                        <div className="m-winnerslist-card__rankings-item-user has-text-weight-bold">
                          {index < 9 ? 0 : ''}
                          {index + 1}.{' '}
                          {changeUserNameWithStarWinners(player?.userid)}
                        </div>
                        <div className="m-winnerslist-card__rankings-item-user has-text-weight-bold has-text-right">
                          {player?.points}
                        </div>
                        <div className="m-winnerslist-card__rankings-item-user has-text-weight-bold has-text-right">
                          {tournaments?.prices.map((item) =>
                            item.position === index + 1
                              ? getPrize(item)
                              : ''
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </>
        )}
        <Link to="/tournaments" className="m-winnerslist-card__rankingsplatform-button">
          {tournaments?.endDate && timer.days ? (
            <span className="m-winnerslist-card__sub-title has-text-weight-bold">
              {t('common:tournament_ends_in')} {timer.days}
              <span className="m-timer-unit">
                {t('common:tournament_days')}
              </span>
              : {timer.hours}
              <span className="m-timer-unit">
                {t('common:tournament_hours')}
              </span>
              : {timer.minutes}
              <span className="m-timer-unit">
                {t('common:tournament_minutes')}
              </span>
              : {timer.seconds}
              <span className="m-timer-unit">
                {t('common:tournament_seconds')}
              </span>
            </span>
          ) : null}
        </Link>
      </div>
    </section>
  );
};

export default CurrentTournamentCard;
