import { SET_USER_STATE, SET_USER_DATA, CLEAR_USER_DATA } from '../actions/userActions';

const initialState = {
  userLoggedIn: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_USER_STATE:
      return {
        ...state,
        userLoggedIn: action.newState,
      };
    case SET_USER_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_USER_DATA:
      return initialState;
    default:
      return state;
  }
}
