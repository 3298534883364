/* eslint-disable import/prefer-default-export */

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import usePlayer from '../usePlayer';
import { gamesApi } from '../../lib/api/cms/gamesService/Games';
import { setActiveGamesCategory } from '../../redux/actions/gamesActions/gamesAction';
import { usePersonalizedSite } from './useGetPersonalizedSite';
import { GamesCache, GamesKeys } from './useGetTopGames';

export function useGetPeopleArePlayingGames() {
  const { player } = usePlayer();
  const [games, setGames] = React.useState();
  const [state, setState] = React.useState('loading');
  const dispatch = useDispatch();
  const { data: personalizedGames, state: personalizedState } =
    usePersonalizedSite();
  const partyId = player?.partyId;

  useEffect(() => {
    if (games?.games?.length || state === 'error' || partyId) return;

    const getData = async () => {
      setState('loading');
      let popularGames = GamesCache.get(GamesKeys.popularGames);

      try {
        dispatch(setActiveGamesCategory({ peopleArePlayingGames: true }));
        if (!popularGames) {
          const response = await gamesApi.getRecommendedGames({
            recommendationType: 'players_like_you',
          });
          if (!response?.data?.items?.length) {
            setState('error');
            return;
          }
          popularGames = (() => {
            if (response?.data?.items?.length >= 12) {
              return response?.data?.items?.slice(0, 12);
            }
            if (response?.data?.items?.length >= 6) {
              return response?.data?.items?.slice(0, 6);
            }
            if (response?.data?.items?.length < 6) {
              return [];
            }
          })();

          if (!popularGames?.length) {
            setState('error');
            return;
          }

          GamesCache.set(GamesKeys.popularGames, popularGames);
        }
        setGames({
          games: popularGames,
        });
        setState('idle');
      } catch (error) {
        setState('error');
      }
    };
    getData();
  }, [partyId, games, state, dispatch]);

  useEffect(() => {
    if (state === 'error') {
      dispatch(setActiveGamesCategory({ peopleArePlayingGames: false }));
    }
  }, [state, dispatch]);

  useEffect(() => {
    if (partyId) {
      setState(personalizedState);
      dispatch(setActiveGamesCategory({ peopleArePlayingGames: true }));
      if (personalizedGames?.because_you_played?.length) {
        const modifedGames = (() => {
          if (personalizedGames?.because_you_played?.length >= 12) {
            return personalizedGames?.because_you_played?.slice(0, 12);
          }
          if (personalizedGames?.because_you_played?.length >= 6) {
            return personalizedGames?.because_you_played?.slice(0, 6);
          }
          if (personalizedGames?.because_you_played?.length < 6) {
            return [];
          }
        })();
        setGames({
          games: modifedGames,
          because_you_played_context:
            personalizedGames?.because_you_played_context ?? [],
        });
      }
    }
  }, [partyId, setState, personalizedState, personalizedGames, dispatch]);

  const text = games?.because_you_played_context?.[0]?.name
    ? `Because You Played ${games?.because_you_played_context?.[0]?.name}`
    : 'People Are Playing';

  return {
    games,
    state,
    text,
  };
}
