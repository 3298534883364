import React from 'react';
import PropTypes from 'prop-types';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tournament-card-tabpanel-${index}`}
      aria-labelledby={`tournament-card-tabs-${index}`}
      className="m-tab-content"
      {...other}
    >
      {value === index && <div className="p-5">{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

TabPanel.defaultProps = {
  children: null,
};

function TournamentCardTabs({ tabs }) {

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(id) {
    return {
      id: `tournament-card-tabs-${id}`,
      'aria-controls': `tournament-card-tabpanel-${id}`,
    };
  }

  const filteredTabs = tabs.filter(tab => tab.hide !== true);

  return (
    <>
      <div className="m-tab-buttons" aria-label="tournament card tabs" role="button">
        {filteredTabs.map((tab, index) => (
          <button type="button" className={value === index ? 'is-active' : ''} key={index} onClick={(event) => handleChange(event, index)} {...a11yProps(index)}>
            {tab.label}
          </button>
        ))}
      </div>
      {filteredTabs.map((tab, index) => (
        <TabPanel key={tab.id} value={value} index={index}>
          {tab.panel}
        </TabPanel>
      ))}
    </>
  );
}

TournamentCardTabs.propTypes = {
  tabs: PropTypes.instanceOf(Array),
};

TournamentCardTabs.defaultProps = {
  tabs: [],
};

export default TournamentCardTabs;