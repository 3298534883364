/* eslint-disable import/prefer-default-export */
import Casino from '@dialinvest/react-casino';
import React, { createContext, useContext, useEffect } from 'react';

export const cache = new Map();

const countryKey = 'country-info';

const CountryInfoContext = createContext();

export const CountryInfoProvider = ({ children }) => {
  const [countryInfo, setCountryInfo] = React.useState(null);
  const [state, setState] = React.useState('loading');

  useEffect(() => {
    if (countryInfo || state === 'error') return;

    const getData = async () => {
      setState('loading');
      const country = new Casino.models.Country();
      let countryIso = cache.get(countryKey);

      try {
        if (!countryIso) {
          const countryResp = await country.getCountryInfo();

          if (countryResp?.data?.status !== 'SUCCESS') {
            throw new Error('something went wrong with to getCountryInfo API');
          }

          cache.set(countryKey, countryResp?.data?.countryInfo?.iso2Code);
          countryIso = countryResp?.data?.countryInfo?.iso2Code;
          setCountryInfo(countryIso);
        }
        setCountryInfo(countryIso);
        setState('idle');
      } catch (error) {
        setState('error');
        console.error(error);
      }
    };
    getData();
  }, [countryInfo, state]);

  return (
    <CountryInfoContext.Provider value={{ data: countryInfo, state }}>
      {children}
    </CountryInfoContext.Provider>
  );
};

export function useGetCountryInfo() {
  const context = useContext(CountryInfoContext);

  if (context === undefined) {
    throw new Error(
      `'useGetCountryInfo' must be used within 'CountryInfoContext'`
    );
  }

  return context;
}
