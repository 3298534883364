import React from "react";
import Casino, { context } from "@dialinvest/react-casino";
import { useTranslation } from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Loader from "react-loader-spinner";
import { changeUserNameWithStarWinners } from "../../Helpers";
import { isoToMark } from "../../Page/TournamentsPage/useGetPrize";
import { getPlayAndGoGamesById } from "../CategoryWinnersLists";
import { Link } from "react-router-dom";

const LatesWinnersCard = () => {
  const carousel = React.useRef(null);
  const { t } = useTranslation();
  const [latestWinners, setLatestWinners] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const { pageType } = React.useContext(context.PageContext);

  React.useEffect(() => {
    if (pageType === "gamePage" && carousel.current && !loading) {
      carousel.current.moveTo(0);
      carousel.current.resetAutoPlay();
    }
  }, [pageType, loading]);

  React.useEffect(() => {
    let active = true;
    setLoading(true);

    const getGameWinnerList = async () => {
      try {
        let fetchedLatestWinners;

        const parameters = {
          domain: `https://${process.env.REACT_APP_FRONTEND_URL}`,
          period: 8,
          size: 25, // size of the winners list
          periodType: "hourly",
          uniqueWinner: true,
        };

        fetchedLatestWinners = await new Casino.GetWinners(
          parameters
        ).perform();

        if (!fetchedLatestWinners.length) {
          delete parameters.periodType;
          fetchedLatestWinners = await new Casino.GetWinners(
            parameters
          ).perform();
        }

        const missingGameIdArray = [];
        let normalizedLatestWinners;

        fetchedLatestWinners.forEach(
          (item) => !item.thumbnail && missingGameIdArray.push(item.gameId)
        );
        fetchedLatestWinners.sort(() => Math.random() - 0.5);

        if (missingGameIdArray.length > 0) {
          const missingGamesData = await getPlayAndGoGamesById(
            missingGameIdArray
          );

          normalizedLatestWinners = fetchedLatestWinners.map((item) => {
            if (!item.thumbnail) {
              const missingGame = missingGamesData?.find(
                (game) => game.reference === item.gameId
              );
              if (missingGame) {
                return {
                  ...item,
                  thumbnail: missingGame.thumbnail,
                  slug: missingGame.slug,
                };
              }
            }
            return item;
          });

          if (active) {
            setLatestWinners(normalizedLatestWinners);
          }
          return;
        }

        if (active) {
          setLatestWinners(fetchedLatestWinners);
        }
      } catch (error) {
        console.log(error);
      } finally {
        if (active) {
          setLoading(false);
        }
      }
    };
    if (active) {
      getGameWinnerList();
    }
    return () => {
      active = false;
    };
  }, []);

  /* istanbul ignore next */
  const onSlide = (currentItem) => {
    if (currentItem > latestWinners.length - 9) {
      carousel.current.moveTo(0);
    }
  };

  return (
    <section
      key="latestWinnerList"
      className="column m-winnerslist-card m-winnerslist-card__latest has-text-white has-text-centered is-active"
    >
      <div className="m-winnerslist-card__container p-1">
        <div className="m-winnerslist-card__header">
          <div className="m-winnerslist-card__maintitle">
            <h2 className="m-winnerslist-card__title is-size-6 has-text-weight-bold ">
              <img
                src="/images/winners-widget/title-latest-winners.png"
                alt={t("common:latest_winners_list_title")}
                loading="lazy"
              />
              <span className="is-sr-only">
                {t("common:latest_winners_list_title")}
              </span>
            </h2>
          </div>
        </div>
        {loading ? (
          <div
            style={{
              height: "80%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loader color="#552c88" type="ThreeDots" />
          </div>
        ) : (
          <>
            <div className="m-winnerslist-card__rankingsplatform">
              {latestWinners?.length > 0 && (
                <Carousel
                  autoPlay={latestWinners.length > 9 && true}
                  axis="vertical"
                  dynamicHeight={false}
                  showThumbs={false}
                  showArrows={false}
                  showStatus={false}
                  showIndicators={false}
                  ref={carousel}
                  onChange={onSlide}
                  interval={1000}
                  stopOnHover={false}
                >
                  {!latestWinners.length || loading
                    ? null
                    : latestWinners?.map((player, index) => (
                        <div
                          key={`${index}_${player.playerName}`}
                          className="m-winnerslist-card__rankingsplatform-item"
                        >
                          <div className="item-container">
                            <div className="m-winnerslist-card__rankingsplatform-item-user is-size-7-mobile p-2 has-text-weight-bold">
                              {changeUserNameWithStarWinners(player.playerName)}
                            </div>
                            <div className="m-winnerslist-card__rankingsplatform-item-score is-size-7-mobile has-text-weight-bold">
                              {isoToMark(player.currency, player.amountWon)}
                            </div>
                            <div className="m-winnerslist-card__rankingsplatform-item-image ">
                              <figure>
                                <img
                                  src={player.thumbnail?.url}
                                  data-flickity-lazyload={player.thumbnail?.url}
                                  alt=""
                                />
                              </figure>
                            </div>
                          </div>
                        </div>
                      ))}
                </Carousel>
              )}
            </div>
          </>
        )}
        <div className="m-winnerslist-card__rankingsplatform-button">
          <Link to="?category=featured" className="has-text-weight-bold">
            {t("buttons:play_our_featured_games")}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default LatesWinnersCard;
