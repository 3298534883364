import React from 'react';
import Casino from '@dialinvest/react-casino';
import Flickity from 'react-flickity-component';
import CurrentTournamentCard from './CurrentTournamentCard';
import LatesWinnersCard from './LatestWinnersCard';
import TopWinnersCard from './TopWinnersCard';

export const getPlayAndGoGamesById = async (id) => {
  // play'n go: id = 85, slug = 'playngo'
  try {
    const response = await new Casino.FetchContent(`games/?reference__in=${id}&disable_blacklist=true`).perform();
    return response.data.items;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const CategoryWinnersList = () => {
  const elementRef = React.useRef();

  const centerElement = () => {
    // eslint-disable-next-line max-len
    elementRef.current.scrollLeft = (elementRef.current.scrollWidth - elementRef.current.clientWidth) / 2;
  };

  React.useEffect(() => {
    if (elementRef.current) {
      centerElement();
    }
  });

  const flickityOptions = {
    lazyload: false,
    pageDots: true,
    prevNextButtons: false,
    adaptiveHeight: true,
    imagesLoaded: true,
    pauseAutoPlayOnHover: false,
    initialIndex: 1,
  };

  return (
    <>
      <section className="m-winnerslist-cards-container ">
        <div className="m-winnerslist-cards-container__box">
          <div id="winnerslist-cards-container-scrollarea" className="m-winnerslist-cards-container__scroll-area" ref={elementRef}>
            <Flickity
              className="carousel"
              options={flickityOptions}
            >
              <LatesWinnersCard />
              <TopWinnersCard />
              <CurrentTournamentCard />
            </Flickity>
          </div>
        </div>
        <div className="m-winnerslist-cards-container__video">
          <video className="m-winnerslist-cards-container__video-bg" preload="true" autoPlay loop muted playsInline>
            <source src="/videos/starryBG_3sec_loop_640x360.mp4" type="video/mp4" />
          </video>
        </div>
      </section>
    </>
  );
};

export default CategoryWinnersList;
