/* eslint-disable camelcase */
import { gamesApi } from '../../../lib/api/cms/gamesService/Games';
import {
  extendProvidersWithStudios,
} from './GamesActionHelpers';
import { setError, updateGames } from './gamesAction';

export const SET_CATEGORIES_AND_PROVIDERS = 'SET_CATEGORIES_AND_PROVIDERS';

export function setCategoriesAndProviders(payload) {
  return {
    type: SET_CATEGORIES_AND_PROVIDERS,
    payload,
  };
}
/* istanbul ignore next */
export const filterByCountryISO = (provider, countryPreview) => {
  if ('countries_blacklist' in provider) {
    if (Array.isArray(provider.countries_blacklist)) {
      return !provider.countries_blacklist.find(
        country => country.country_iso === countryPreview,
      )?.id;
    }
  }
  return true;
};
/* istanbul ignore next */
export const filterByDomainWhiteList = playerEmail => (provider) => {
  if ('domains_whitelist' in provider) {
    if (
      Array.isArray(provider.domains_whitelist)
      && provider.domains_whitelist.length
    ) {
      const domainWhiteList = [];
      return !provider.domains_whitelist.some((domain) => {
        if (playerEmail.includes(domain)) {
          domainWhiteList.push(provider.id);
        }

        return playerEmail.includes(domain);
      });
    }
  }
  return true;
};
/* istanbul ignore next */
export const filterProviders = (providers, emailDomain) => {
  const filteredProviders = providers?.filter(
    filterByDomainWhiteList(emailDomain),
  );
  return filteredProviders;
};

export function initCategoriesAndProviders(countryPreview) {
  return async (dispatch, getState) => {
    const {
      games: { categories, providers },
    } = getState();

    let categoriesResponse;
    let providersResponse;
    const notAllowedProviders = [];

    if (!categories.items.length && !providers.items.length) {
      const [categoriesResult, providersResult] = await Promise.all([
        gamesApi.getCategories(),
        gamesApi.getProviders(),
      ]);

      categoriesResponse = categoriesResult;
      providersResponse = providersResult;
    }

    const extendedProviders = extendProvidersWithStudios(providersResponse?.data?.items)('domains_whitelist');

    const enabledProviders = extendedProviders
      ?.filter((provider) => {
        if (provider.status !== 'enabled') notAllowedProviders.push(provider);
        return provider.status === 'enabled';
      });


    const data = {
      categories: {
        items: categoriesResponse?.data?.items || [],
        count: categoriesResponse?.data?.meta?.total_count || 0,
      },
      providers: {
        items: enabledProviders || [],
        count: enabledProviders?.length || 0,
        countryPreview,
        notAllowedProviders,
      },
    };

    // Filter providers by countryPreview
    if (data.providers.items?.length && countryPreview) {
      const filteredProvidersByCountryPreview = data.providers.items.filter(
        provider => filterByCountryISO(provider, countryPreview),
      );

      data.providers.items = filteredProvidersByCountryPreview;
      data.providers.count = filteredProvidersByCountryPreview.length;
    }

    if (
      categoriesResponse?.status === 200
      && providersResponse?.status === 200
    ) {
      dispatch(setCategoriesAndProviders({ ...data }));
    } else if (categoriesResponse?.error || providersResponse?.error) {
      dispatch(
        setError({
          categories: categoriesResponse?.error
            ? categoriesResponse
            : providersResponse,
        }),
      );
    }
  };
}

export function updateGamesAndCategories(
  sessionKey,
  emailDomain,
  countryPreview,
) {
  return async (dispatch, getState) => {
    const {
      games: {
        categories,
        providers,
        gamesByCategories,
        activeGame,
      },
    } = getState();

    if (!categories.items.length) return;

    const dataToUpdate = {
      categories,
      gamesByCategories,
      providers: {
        items: providers?.items || [],
        notAllowedProviders: providers.notAllowedProviders || [],
        whiteListedDomains: providers.whiteListedDomains || [],
        countryPreview,
        allowedProviders: [],
      },
      activeGame,
    };

    // UPDATE Games and Categories and Providers if the player is logged in:
    // *********************************************************************
    if (sessionKey) {
      // handle domainWhitelists in providers & categories & games
      if (emailDomain) {
        const addDisabledProvidersBasedOnWhitelist = providers.notAllowedProviders.filter(provider => provider.domains_whitelist.includes(emailDomain)).map(provider => ({
          ...provider,
          isWhiteListed: true,
        }));
        const modifedNotAllowedProviders = providers.notAllowedProviders.filter(provider => !provider.domains_whitelist.includes(emailDomain));

        const mergedProviders = [...providers.items, ...addDisabledProvidersBasedOnWhitelist];
        dataToUpdate.providers.items = mergedProviders;
        dataToUpdate.providers.count = mergedProviders?.length;
        dataToUpdate.providers.allowedProviders = addDisabledProvidersBasedOnWhitelist;
        dataToUpdate.providers.notAllowedProviders = modifedNotAllowedProviders;
      }

      dispatch(updateGames(dataToUpdate));
    }
  };
}