import React from 'react';
import GameNavigation from '../GameNavigation';
import Games from './Games';
import { PersonalizedSiteProvider } from '../../../hooks/games/useGetPersonalizedSite';
import { CountryInfoProvider } from '../../../hooks/games/useGetCountryInfo';

function GameBrowser() {
  return (
    <PersonalizedSiteProvider>
      <CountryInfoProvider>
        <GameNavigation />
        <Games />
      </CountryInfoProvider>
    </PersonalizedSiteProvider>
  );
}

export default GameBrowser;
