import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Casino from '@dialinvest/react-casino';
import { NavLink } from 'react-router-dom';
import { getPathname } from '../../Helpers';
import BackgroundImage from '../../Layout/BackgroundImage';

const FeaturedArticle = ({ data, title, showTitle }) => {
  const [featuredArticle, setFeaturedArticle] = useState({});
  const fetchFeaturedArticle = async (id) => {
    const result = await new Casino.FetchContent(`pages/${id}`).perform();
    setFeaturedArticle(result.data);
  };

  useEffect(() => {
    if (data.id) {
      fetchFeaturedArticle(data.id);
    }
  }, [data]);

  const getExcerpt = () => {
    let excerpt;
    if (featuredArticle.excerpt) {
      excerpt = featuredArticle.excerpt;
    } else {
      excerpt = '';
    }
    return excerpt;
  };

  let imageSrc = '';
  let componentBody;

  if (featuredArticle.banner) imageSrc = featuredArticle.banner.meta.download_url;
  if (featuredArticle?.banner_video) imageSrc = '';

  let linkComponent;

  if (featuredArticle.button_target) {
    linkComponent = (
      <NavLink
        to={getPathname(featuredArticle.button_target.html_url)}
        className="button is-dark-green is-size-4-tablet"
        ref={
          (el) => {
            if (el) {
              el.style.setProperty('color', featuredArticle.button_color, 'important');
              el.style.setProperty('background-color', featuredArticle.button_background_color, 'important');
              el.style.setProperty('border-color', featuredArticle.button_background_color, 'important');
            }
          }
        }
      >
        {`${featuredArticle.button_label || featuredArticle.title}`}
      </NavLink>
    );
  } else if (featuredArticle.button_external_link) {
    linkComponent = (
      <a
        href={featuredArticle.button_external_link}
        className="button is-dark-green is-size-4-tablet"
        ref={
          (el) => {
            if (el) {
              el.style.setProperty('color', featuredArticle.button_color, 'important');
              el.style.setProperty('background-color', featuredArticle.button_background_color, 'important');
              el.style.setProperty('border-color', featuredArticle.button_background_color, 'important');
            }
          }
        }
      >
        {`${featuredArticle.button_label || featuredArticle.title}`}
      </a>
    );
  } else if (featuredArticle.meta) {
    linkComponent = (
      <NavLink
        to={getPathname(featuredArticle.meta.html_url)}
        className="button is-dark-green is-size-4-tablet"
        ref={
          (el) => {
            if (el) {
              el.style.setProperty('color', featuredArticle.button_color, 'important');
              el.style.setProperty('background-color', featuredArticle.button_background_color, 'important');
              el.style.setProperty('border-color', featuredArticle.button_background_color, 'important');
            }
          }
        }
      >
        {`${featuredArticle.button_label || featuredArticle.title}`}
      </NavLink>
    );
  }

  if (Object.keys(featuredArticle).length > 0) {
    componentBody = (
      <div className="columns is-multiline">
        <div className="column is-12 has-text-centered">
          <p className="m-promo-news-banner-sub-title has-text-white is-size-2 is-size-4-mobile" dangerouslySetInnerHTML={{ __html: getExcerpt() }} />
          { linkComponent }
        </div>
      </div>
    );
  }

  return (
    <div className="column content">
      <aside className="m-promo-news-banner-area ">
        {featuredArticle?.featured_banner_video ? (
          <div className="m-promo-news-banner m-promo-video-banner has-background-gradient-neon">
            <div className="m-promo-video-area">
              <video muted loop autoPlay playsInline>
                <source src={featuredArticle.featured_banner_video.url} type="video/mp4" />
              </video>
            </div>
            <div className="m-promo-banner-overlay">
              {showTitle && (
                <p className="m-promo-news-banner-title has-background-gradient-neon has-text-white is-size-1 is-size-4-mobile">
                  {title}
                </p>
              )}
              {componentBody}
            </div>
          </div>
        ) : (
          <BackgroundImage elementClass="m-promo-news-banner has-background-dark" src={imageSrc}>
            {showTitle && (
              <p className="m-promo-news-banner-title has-background-gradient-neon has-text-white is-size-1 is-size-4-mobile">
                {title}
              </p>
            )}
            {componentBody}
          </BackgroundImage>
        )}
      </aside>
    </div>
  );
};

FeaturedArticle.propTypes = {
  data: PropTypes.instanceOf(Object),
  title: PropTypes.string.isRequired,
  showTitle: PropTypes.bool,
};

FeaturedArticle.defaultProps = {
  showTitle: true,
  data: {},
};

export default FeaturedArticle;
