/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FavoriteButton from './FavoriteButton';
import ProviderLogo from './GameProviderLogo';
import useGames from '../../../hooks/games/useGames';

const GameThumbnail = ({
  id,
  reference,
  provider,
  name,
  thumbnail,
  supports_demo_play,
  tags,
  slug,
  status,
  mega_thumb,
  vertical_thumbnail,
  favorite,
  studio,
  is_new,
}) => {
  const [loadImage, setloadImage] = useState(null);
  const { t, i18n } = useTranslation();
  const el = useRef(null);
  const { providers } = useGames();

  useEffect(() => {
    let image = 'https://placehold.it/450x300';
    if (thumbnail) image = thumbnail.url;

    const LoadImages = ({ image, name }) => (
      <figure>
        <img
          src={image}
          alt={name}
          height="100%"
          width="100%"
          loading="lazy"
        />
        {is_new
        && <div className="m-game-grid-item__label">{ t('common:new') }</div>}
      </figure>
    );
    setloadImage(LoadImages({ image, name }));
  }, [thumbnail]);

  const createUrl = demoPlay => ({
    pathname: `/${i18n.language}/games/${slug}/`,
    search: !demoPlay ? '?mode=real' : '',
    state: {
      supportsDemoPlay: supports_demo_play,
      prevPath: window.location.pathname + window.location.search,
    },
  });

  const GameNavLink = () => (
    <>
      <ProviderLogo providerList={providers.items} slug={studio?.slug ? studio.slug : provider.slug} />

      <FavoriteButton
        key={id}
        id={reference}
        provider={provider.id}
        favorite={favorite}
      />

      <Link key={`${slug}real`} to={createUrl(false)} className="button is-dark-green">
        <i className="icon-play-real" />
        { t('buttons:play_for_real') }
      </Link>
      {supports_demo_play
      && (
        <Link key={`${slug}free`} to={createUrl(true)} className="button-play-for-free">
          { t('buttons:play_for_free') }
        </Link>
      )}
    </>
  );

  const GameMaintenance = () => (
    <div className="button is-danger is-maintenance">
      <i className="icon-maintenance is-size-3" />
      <br />
      <p>{ t('buttons:under_maintenance') }</p>
    </div>
  );

  return (
    <div
      className={
        `m-game-grid-item column
        ${mega_thumb ? 'featured-grid' : ''}
        ${vertical_thumbnail?.id ? 'vertical-grid' : ''}`
      }
      ref={el}
    >
      {loadImage}
      <div className="m-game-grid-item__shadow" />
      <div className="m-game-grid-buttons is-flex">
        {status === 'enabled' ? GameNavLink() : GameMaintenance()}
      </div>
    </div>
  );
};

GameThumbnail.propTypes = {
  name: PropTypes.string.isRequired,
  thumbnail: PropTypes.instanceOf(Object),
  supports_demo_play: PropTypes.bool.isRequired,
  tags: PropTypes.instanceOf(Array).isRequired,
  slug: PropTypes.string,
  status: PropTypes.string.isRequired,
  mega_thumb: PropTypes.bool,
  vertical_thumbnail: PropTypes.instanceOf(Object),
  id: PropTypes.number,
  provider: PropTypes.instanceOf(Object),
  studio: PropTypes.instanceOf(Object),
  reference: PropTypes.string,
  favorite: PropTypes.bool,
  is_new: PropTypes.bool,
};

GameThumbnail.defaultProps = {
  thumbnail: null,
  mega_thumb: false,
  vertical_thumbnail: {},
  id: 0,
  favorite: false,
  slug: '',
  reference: '',
  provider: {},
  studio: {},
  is_new:false
};

export default GameThumbnail;
