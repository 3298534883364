import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { goToAnchor, configureAnchors } from 'react-scrollable-anchor';
import moment from 'moment';
import Casino from '@dialinvest/react-casino';
import BasePage from '../Base';
import 'flickity-fade';
import TournamentsPageHeader from './TournamentsPageHeader';
import TournamentsPageHome from './TournamentsPageHome';
import TournamentsPageCalendar from './TournamentsPageCalendar';
import TournamentsPagePast from './TournamentsPagePast';
import TournamentsPageTextContent from './TournamentsPageTextContent';
import {
  checkSessionCookie, clipHTMLBody,
} from '../../Helpers';

export class TournamentsPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tournaments: [],
      providerTournaments: [],
      pageContent: [],
      active: '',
      cookieValues: '',
    };
    this.menuID = '1';
  }

  componentDidMount() {
    this.fetchThisAllArticles();
    this.fetchSubPages();
    this.handleMenuClick();
  }

  componentDidUpdate(prevProps) {
    const { loginIsOpen } = this.props;

    if (loginIsOpen === false && prevProps.loginIsOpen === true) {
      this.fetchThisAllArticles();
    }
  }

  fetchThisAllArticles = async () => {
    const today = moment().utc().format('YYYY-MM-DD HH:mm:ss');
    const pastTournamentsFrom = moment().utc().subtract(2, 'months').format('YYYY-MM-DD HH:mm:ss');

    const urlMenu2 = `tournaments/?starts_at_gte=${today}&ends_at_gte=${today}`;
    const urlMenu3 = `tournaments/?ordering=-ends_at&ends_at_gte=${pastTournamentsFrom}&ends_at_lte=${today}`;
    const urlMenuDefault = `tournaments/?starts_at_lte=${today}&ends_at_gte=${today}`;

    const providerUrlMenu2 = `provider-tournaments/?starts_at_gte=${today}&ends_at_gte=${today}`;
    const providerUrlMenu3 = `provider-tournaments/?ordering=-ends_at&ends_at_gte=${pastTournamentsFrom}&ends_at_lte=${today}`;
    const providerUrlMenuDefault = `provider-tournaments/?starts_at_lte=${today}&ends_at_gte=${today}`;

    let url;
    let providerUrl;
    switch (this.menuID) {
      case '2':
        url = urlMenu2;
        providerUrl = providerUrlMenu2;
        break;
      case '3':
        url = urlMenu3;
        providerUrl = providerUrlMenu3;
        break;
      default:
        url = urlMenuDefault;
        providerUrl = providerUrlMenuDefault;
        break;
    }

    this.fetchArticles(url, 'tournaments', urlMenu2, urlMenu3, urlMenuDefault);
    this.fetchArticles(providerUrl, 'providerTournaments', providerUrlMenu2, providerUrlMenu3, providerUrlMenuDefault);
  };

  fetchArticles = async (url, stateName, urlMenu2, urlMenu3, urlMenuDefault) => {
    if (checkSessionCookie()) {
      await this.setState({ cookieValues: JSON.parse(new Casino.Cookie().get(process.env.REACT_APP_API_COOKIE_NAME)) });
    }
    const result = await new Casino.FetchContent(url).perform();

    if (url === urlMenu2 && this.menuID !== '2') {
      return;
    }
    if (url === urlMenu3 && this.menuID !== '3') {
      return;
    }
    if (url === urlMenuDefault && this.menuID !== '1') {
      return;
    }

    this.setState({
      [stateName]: result.items(),
      [`${stateName}Fetched`]: true,
    });
  };

  handleSignup = async (event) => {
    event.preventDefault();
    const { onToggleModalIn } = this.props;
    onToggleModalIn();
    clipHTMLBody();
  };

  handleMenuClick = async (event) => {
    this.menuID = event?.target?.id;
    await this.fetchSubPages();
    this.setState({
      tournaments: [],
      providerTournaments: [],
    }, () => {
      this.fetchThisAllArticles();
      configureAnchors({ offset: 1 });
      goToAnchor('tournamentsNav', true);
    });
  };

  showMenu = (event) => {
    event.preventDefault();

    event.currentTarget.classList.toggle('is-active');
    const navBurger = document.querySelector('.m-tournament-nav-button .navbar-burger');
    const navbarMenu = document.querySelector('.navbar-menu.m-tournament-nav-menu');
    navBurger.classList.toggle('is-active');
    navbarMenu.classList.toggle('is-active');
  };

  fetchSubPages = async () => {
    const { data } = this.props;

    const pageContent = data;
    
    this.setState({
      pageContent,
    });
  };

  contentSelector = () => {
    const { t, loginIsOpen } = this.props;
    const {
      cookieValues,
      active,
      tournaments,
      providerTournaments,
      pageContent,
      calendarPage,
    } = this.state;

    const urlEnd = window.location.pathname.split('/').pop();
    switch (urlEnd) {
      case 'calendar':
        this.menuID = '2';
        break;
      case 'past':
        this.menuID = '3';
        break;
      case 'about':
        this.menuID = '4';
        break;
      case 'rules':
        this.menuID = '5';
        break;
      default:
        this.menuID = '1';
    }

    switch (this.menuID) {
      case '2':
        return (
          <TournamentsPageCalendar
            cookieValues={cookieValues}
            active={active}
            items={[...tournaments, ...providerTournaments]}
            onSignupAction={this.handleSignup}
            menuID={this.menuID}
            t={t}
            calendarPage={calendarPage}
          />
        );
      case '3':
        return (
          <TournamentsPagePast
            cookieValues={cookieValues}
            active={active}
            items={[...tournaments, ...providerTournaments]}
            onSignupAction={this.handleSignup}
            menuID={this.menuID}
            t={t}
          />
        );
      case '4':
        return (
          <TournamentsPageTextContent
            page={pageContent}
          />
        );
      case '5':
        return (
          <TournamentsPageTextContent
            page={pageContent}
          />
        );
      default:
        return (
          <TournamentsPageHome
            cookieValues={cookieValues}
            active={active}
            items={[...tournaments, ...providerTournaments]}
            onSignupAction={this.handleSignup}
            menuID={this.menuID}
            loginIsOpen={loginIsOpen}
            t={t}
          />
        );
    }
  };

  render() {
    const { t, i18n, data } = this.props;

    return (
      <div className="m-tournament">
        <div className="m-tournament-layout">
          <BasePage data={data}>
            <div className="container">
              <TournamentsPageHeader
                data={data}
                showMenu={this.showMenu}
                t={t}
                i18n={i18n}
                handleMenuClick={this.handleMenuClick}
              />
              {this.contentSelector()}
            </div>
          </BasePage>
        </div>
      </div>
    );
  }
}

TournamentsPage.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    banner: PropTypes.instanceOf(Object),
    description: PropTypes.string.isRequired,
    background_image: PropTypes.instanceOf(Object),
    foreground_image: PropTypes.instanceOf(Object),
    meta: PropTypes.instanceOf(Object),
  }),
  t: PropTypes.func.isRequired,
  i18n: PropTypes.func.isRequired,
  onToggleModalIn: PropTypes.func.isRequired,
  loginIsOpen: PropTypes.bool.isRequired,
};

TournamentsPage.defaultProps = {
  data: {},
};

export const mapStateToProps = state => ({
  userLoggedIn: state.userState.userLoggedIn,
  loginIsOpen: state.signInModal?.isOpen,
});

export const mapDispatchToProps = dispatch => ({
  onToggleModalIn: () => dispatch({ type: 'TOGGLE_MODAL_IN' }),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  withRouter,
)(TournamentsPage);
