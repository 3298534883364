import React from 'react';
import PropTypes from 'prop-types';
import TournamentWidget from './TournamentWidget';

const TournamentsWidgetList = ({
  cookieValues,
  active,
  items,
  onSignupAction,
  menuID,
  loginIsOpen,
}) => (
  <div className="m-tournament-widgets columns is-centered is-multiline is-desktop">
    {items.map(item => (
      <div className="column is-12-mobile is-6-desktop" key={item.id}>
        <TournamentWidget
          key={item.id}
          cookieValues={cookieValues}
          active={active}
          id={item.id}
          name={item.name}
          description={item.description}
          price={item.price}
          prize={item.prize}
          start={item.starts_at}
          end={item.ends_at}
          players={item.players}
          prices={item.prices}
          games={item.games}
          onSignupAction={onSignupAction}
          menuID={menuID}
          omegaID={item.omega_id}
          loginIsOpen={loginIsOpen}
          banner={item.banner}
          isPast={item.isPast}
          provider={item.provider}
          providerDetail={item.detail}
        />
      </div>
    ))}
  </div>
);

TournamentsWidgetList.propTypes = {
  cookieValues: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  active: PropTypes.string,
  items: PropTypes.instanceOf(Array),
  onSignupAction: PropTypes.func.isRequired,
  menuID: PropTypes.string.isRequired,
  loginIsOpen: PropTypes.bool.isRequired,
};

TournamentsWidgetList.defaultProps = {
  cookieValues: '',
  active: '',
  items: [],
};

export default TournamentsWidgetList;
