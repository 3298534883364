import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AccountBalanceWrapper from './AccountBalanceWrapper';

export const AccountBalanceBody = ({ balance, bonusPage }) => {
  const { t } = useTranslation('account');

  return (
    <>
      <div className={`column ${bonusPage ? 'is-12-tablet is-6-desktop' : 'is-12-mobile is-4'}`}>
        <div className="card m-user-dashboard-info has-background-white">
          <div className="card-content ">
            <div className="level is-mobile">
              <div className="level-left">
                <h3 className="has-text-primary is-marginless is-size-6-touch is-size-5">
                  <i className="icon-deposit" />
                  &nbsp;
                  { t('your_balance') }
                </h3>
              </div>
              <div className="level-right">
                <h3 className="is-marginless is-size-6-touch is-size-5"><strong className="has-text-primary">{balance}</strong></h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

AccountBalanceBody.propTypes = {
  balance: PropTypes.string.isRequired,
  bonusPage: PropTypes.bool,
};

AccountBalanceBody.defaultProps = {
  bonusPage: false,
};

const AccountBalance = ({ bonusPage }) => (
  <AccountBalanceWrapper>
    {({ balance }) => <AccountBalanceBody balance={balance} bonusPage={bonusPage} />}
  </AccountBalanceWrapper>
);

AccountBalance.propTypes = {
  bonusPage: PropTypes.bool,
};

AccountBalance.defaultProps = {
  bonusPage: false,
};

export default AccountBalance;
