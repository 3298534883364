import React from 'react';
import PropTypes from 'prop-types';
import { changeUserNameWithStar } from '../../Helpers';
import useGetPrize from './useGetPrize';

const PlayersWidget = ({
  optedPlayers,
  currentPlayer,
  currentPrices,
  onActiveRank,
}) => {
  const sortedPlayers =
    optedPlayers.length !== 0
      ? [...optedPlayers]?.sort((a, b) => b.points - a.points)
      : [];
  const { getPrize } = useGetPrize();
    /* istanbul ignore next */
  const normalizedList = React.useMemo(() => {
    if (sortedPlayers.length >= currentPrices.length) {
      return Array(sortedPlayers.length).fill(true);
    }
    return Array(currentPrices.length).fill(true);
  }, [sortedPlayers, currentPrices]);

  return (
    <table className="table table is-fullwidth">
      <tbody>
        {normalizedList?.map((_, i) => {
          if (sortedPlayers[i] !== undefined) {
            const isActive =
              sortedPlayers[i]?.userid.toLowerCase() ===
              currentPlayer.toLowerCase();
            const userNameWithStar = changeUserNameWithStar(
              sortedPlayers[i]?.userid
            );

            if (isActive) onActiveRank(i + 1);
            return (
              <tr
                key={sortedPlayers[i]?.userid}
                className={`${isActive ? 'is-active' : ''}`}
              >
                <td className="m-user-rank has-text-left">
                  {i < 9 ? 0 : ''}
                  {i + 1}
                </td>
                <td className="m-user-name has-text-left">
                  {sortedPlayers[i]?.userid !== undefined
                    ? userNameWithStar
                    : ''}
                </td>
                <td className="m-user-score has-text-right">
                  {sortedPlayers[i]?.points}
                </td>
                <td className="m-user-prize has-text-right">
                  {currentPrices[i] ? getPrize(currentPrices[i]) : ''}
                </td>
              </tr>
            );
          }

          return (
            <tr className="">
              <td className="m-user-rank has-text-left">
                {i < 9 ? 0 : ''}
                {i + 1}
              </td>
              <td className="m-user-name has-text-left" />
              <td className="m-user-score" />
              <td className="m-user-prize has-text-right">
                {currentPrices[i] ? getPrize(currentPrices[i]) : ''}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

PlayersWidget.propTypes = {
  optedPlayers: PropTypes.instanceOf(Array),
  currentPrices: PropTypes.instanceOf(Array),
  currentPlayer: PropTypes.string.isRequired,
  onActiveRank: PropTypes.func.isRequired,
};

PlayersWidget.defaultProps = {
  optedPlayers: [],
  currentPrices: [],
};

export default PlayersWidget;
