/* eslint-disable import/prefer-default-export */

import React, { createContext, useEffect, useContext } from 'react';
import usePlayer from '../usePlayer';
import { gamesApi } from '../../lib/api/cms/gamesService/Games';

const PersonalizedSiteContext = createContext();

export const PersonalizedSiteProvider = ({ children }) => {
  const { player } = usePlayer();
  const [games, setGames] = React.useState(null);
  const [state, setState] = React.useState('loading');

  useEffect(() => {
    if (games || state === 'error' || !player?.partyId) return;

    const getData = async () => {
      setState('loading');
      try {
        const response = await gamesApi.getPersonalizedSite({
          playerID: player?.partyId,
        });

        setGames(response?.data);
        setState('idle');
      } catch (error) {
        setState('error');
      }
    };
    getData();
  }, [player.partyId, games, state]);

  return (
    <PersonalizedSiteContext.Provider value={{ data: games, state }}>
      {children}
    </PersonalizedSiteContext.Provider>
  );
};

export const usePersonalizedSite = () => {
  const context = useContext(PersonalizedSiteContext);

  if (context === undefined) {
    throw new Error(
      `'usePersonalizedSite' must be used within 'PersonalizedSiteContext'`
    );
  }

  return context;
};
