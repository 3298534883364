import React from 'react';
import PropTypes from 'prop-types';
import TournamentsWidgetList from './TournamentsWidgetList';

const TournamentsPageHome = ({
  cookieValues,
  active,
  items,
  games,
  onSignupAction,
  menuID,
  t,
  loginIsOpen,
}) => (
  <>
    <section className="section m-tournament-main">
      <div className="columns is-tablet">
        <div className="column is-12-mobile">
          <div
            className="m-tournaments-main-subtitle has-background-color-linear tournaments-subtitle is-size-4 is-size-6-mobile has-text-weight-bold"
          >
            {t('common:tournament_current')}
          </div>

          <div className="content my-6 ml-4 is-size-5 color-primary">
            <p>
              {t('common:tournament_description')}
            </p>
          </div>

          <TournamentsWidgetList cookieValues={cookieValues} active={active} items={items} games={games} onSignupAction={onSignupAction} menuID={menuID} loginIsOpen={loginIsOpen} />
        </div>

      </div>
    </section>
  </>
);

TournamentsPageHome.propTypes = {
  cookieValues: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  active: PropTypes.string,
  items: PropTypes.instanceOf(Array),
  games: PropTypes.instanceOf(Array),
  onSignupAction: PropTypes.func.isRequired,
  menuID: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  loginIsOpen: PropTypes.bool.isRequired,
};

TournamentsPageHome.defaultProps = {
  cookieValues: '',
  active: '',
  items: [],
  games: [],
};

export default TournamentsPageHome;
