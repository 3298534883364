import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import HeroBanner from '../../HeroBanner';
import GameBrowser from '../../Game/GameBrowser';
import BasePage from '../Base';
import { isUserLoggedIn } from '../../Helpers';
import LoggedInHeroSection from './LoggedInHeroSection';

const HomePage = ({ data }) => (
  <div className="home-page-wrapper">
    <BasePage data={data}>
      {!isUserLoggedIn() && data.carousel && <HeroBanner carousel={data.carousel} />}
      {isUserLoggedIn() && <LoggedInHeroSection featuredPromotion={data.featured_promotion} />}
      <GameBrowser />
    </BasePage>
  </div>
);

HomePage.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    carousel: PropTypes.string.isRequired,
    featured_promotion: PropTypes.instanceOf(Object),
  }),
};

HomePage.defaultProps = {
  data: {
    featured_promotion: {},
  },
};

export const mapStateToProps = state => ({
  userLoggedIn: state.userState.userLoggedIn,
});

export default compose(
  connect(mapStateToProps),
)(HomePage);
